import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'

// @ts-ignore
import PwaUpdate from 'assets/pwa/PwaUpdate.webm'

const AppLoading = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <video src={PwaUpdate} autoPlay loop muted controls={false} className={classes.amimationFlame} />
      <div className={classes.loadingText}>
        Loading Demex
        <div className={classes.dotPulse}></div>
      </div>
    </div>
  )
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    placeContent: 'center',
    height: '100dvh',
    background: '#000',
  },
  loadingText: {
    ...theme.typography.h5,
    position: 'absolute',
    bottom: '2rem',
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  amimationFlame: {
    scale: 0.5,
  },
  dotPulse: {
    position: 'relative',
    left: '-9999px',
    width: '3px',
    height: '3px',
    borderRadius: '1.5px',
    backgroundColor: theme.palette.text.secondary,
    boxShadow: '9999px 0 0 -1.5px',
    animation: '$dotPulse 1.5s infinite linear',
    animationDelay: '0.25s',
    '&:before, &:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '0',
      width: '3px',
      height: '3px',
      borderRadius: '1.5px',
      backgroundColor: theme.palette.text.secondary,
    },
    '&:before': {
      boxShadow: '9989px 0 0 -1.5px',
      animation: '$dotPulseBefore 1.5s infinite linear',
      animationDelay: '0s',
    },
    '&:after': {
      boxShadow: '10009px 0 0 -1.5px',
      animation: '$dotPulseAfter 1.5s infinite linear',
      animationDelay: '0.5s',
    },
  },
  '@keyframes dotPulseBefore': {
    '0%, 60%, 100%': { boxShadow: '9989px 0 0 -1.5px' },
    '30%': { boxShadow: '9989px 0 0 0.75px' },
  },
  '@keyframes dotPulse': {
    '0%, 60%, 100%': { boxShadow: '9999px 0 0 -1.5px' },
    '30%': { boxShadow: '9999px 0 0 0.75px' },
  },
  '@keyframes dotPulseAfter': {
    '0%, 60%, 100%': { boxShadow: '10009px 0 0 -1.5px' },
    '30%': { boxShadow: '10009px 0 0 0.75px' },
  },
}))


export default AppLoading
