import { useState, useEffect, useMemo } from 'react'

interface WindowDimensions {
  width: number
  height: number
}

interface HeightObj {
  desktop: number
  mobile: number
}

interface Dimensions {
  windowSize: WindowDimensions
  marketsTableHeight: HeightObj
  tradeUIRoot: WindowDimensions
  otherPagesHeight: HeightObj
  gridPadding: number
}

// Heights for Common Components (for calculation of div size)
// NOTE: Remember to update if updating menu bar/footer/markets select UI

const menuBar = 48 // top menu bar (Trade/Staking/Pools/Leaderboard/etc.)
const marketsBar = 60 // markets information bar + margin
const marketsTableFilter = 60 + 42 // markets search filter bar - table header row
const marketTableLabel = 38 // market, price, change, 24h vol
const desktopFooterBar = 40 // desktop footer bar (socials + powered by icon)
const tabletFooterBar = 36 // tablet footer bar (socials + powered by icon)
const mobileFooterBar = 96 // mobile footer bar (socials + powered by icon)
const gridPadding = 4 // padding between boxes
export const marketsHistoryHeight = 76 // markets search history + margin

export const gridRowHeight = 50
export const gridRowHeightMobile = 58

const useDimensions = (): Dimensions => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  // let tableHeight = widthSmDown ? marketsTableHeight.mobile-36 : 432

  // For occupiable space on Trade UI (i.e. window size - top bar - markets bar - footer - grid y-margin)
  const tradeUIRoot = useMemo((): WindowDimensions => {
    const tradeUIRootHeight = windowSize.height - menuBar - marketsBar - desktopFooterBar
    return {
      width: windowSize.width,
      height: tradeUIRootHeight - (gridPadding * 7),
    }
  }, [windowSize])

  // For markets table height
  const marketsTableHeight = useMemo((): HeightObj => {
    return {
      desktop: tradeUIRoot.height - marketsTableFilter - marketTableLabel,
      mobile: windowSize.height - menuBar - marketsTableFilter - marketTableLabel - 150, // window height - header bar - label bar - space for mobile
    }
  }, [tradeUIRoot.height, windowSize])

  // For occupiable space on other pages (i.e. window size - top bar - footer)
  const otherPagesHeight = useMemo((): HeightObj => {
    const height = windowSize.height - menuBar
    return {
      desktop: height - (windowSize.width > 600 && windowSize.width <= 960 ? tabletFooterBar : desktopFooterBar), // window height - header bar - footer
      mobile: height - (windowSize.width > 600 && windowSize.width <= 960 ? tabletFooterBar : mobileFooterBar), // window height - header bar - footer
    }
  }, [windowSize])

  useEffect(() => {
    const handleResizeWindow = () => setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })

    window.addEventListener('resize', handleResizeWindow)
    return () => window.removeEventListener('resize', handleResizeWindow)
  }, [setWindowSize])

  return {
    windowSize,
    marketsTableHeight,
    tradeUIRoot,
    otherPagesHeight,
    gridPadding,
  }
}

export default useDimensions
