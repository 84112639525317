import React, { useEffect, useState } from 'react'

import TextField, { TextFieldProps } from 'js/components/Common/TextField'
import { cleanNumericalInputString } from 'js/utils'

export interface NumberFieldProps extends Omit<TextFieldProps, 'type'> {
  value?: string
}

const DEFAULT_VALUE = '0'

const NumberField: React.FC<NumberFieldProps> = (props: NumberFieldProps) => {
  const { value = '', onChange, onFocus, onBlur, ...rest } = props
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(DEFAULT_VALUE)

  useEffect(() => {
    if (isFocused) return
    if (inputValue === '') {
      setInputValue(DEFAULT_VALUE)
    }
  }, [isFocused, inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value.trim())
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value.trim())
    if (onChange) {
      onChange(event)
    }
  }

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    const cleanedValue = cleanNumericalInputString(inputValue)
    setInputValue(cleanedValue)
    if (onChange) {
      const cleanedEvent = { ...event, target: { ...event.target, value: cleanedValue } }
      onChange(cleanedEvent)
    }

    if (onBlur) {
      onBlur(event)
    }
  }

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    if (inputValue === DEFAULT_VALUE) {
      setInputValue('')
    }
    if (onFocus) {
      onFocus(event)
    }
  }


  return (
    <TextField
      {...rest}
      value={inputValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      type="number"
    />
  )
}

export default NumberField
