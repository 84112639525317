import { CarbonSDK, MetaMask } from 'carbon-js-sdk'
import { useEffect, useMemo, useRef } from 'react'

import { logger } from 'js/utils'

export interface UseMetaMaskResult {
  metamask: MetaMask | undefined
  registerListener: (listener: MetamaskChangeListener) => (() => void)
  registerListenerWallet: (listener: MetamaskChangeListener) => (() => void)
}

export interface MetamaskChangeListener {
  (): void | Promise<void>
}

interface MetaMaskChangeListeners {
  [index: string]: MetamaskChangeListener
}

const connectChangeListeners: MetaMaskChangeListeners = {}
const networkChangeListeners: MetaMaskChangeListeners = {}
const walletChangeListeners: MetaMaskChangeListeners = {}

let listenerId = 0
let listenerIdWallet = 0
let networkListener = false
let walletListener = false
let connectListener = false

const registerListener = (listener: MetamaskChangeListener) => {
  const id = listenerId++
  networkChangeListeners[id] = listener
  const deregisterListener = () => removeListener(id)
  return deregisterListener
}

const registerListenerWallet = (listener: MetamaskChangeListener) => {
  const id = listenerIdWallet++
  walletChangeListeners[id] = listener
  const deregisterListener = () => removeListenerWallet(id)
  return deregisterListener
}

const removeListener = (id: number) => {
  delete networkChangeListeners[id]
}

const removeListenerWallet = (id: number) => {
  delete walletChangeListeners[id]
}

interface ConnectInfo {
  chainId: string;
}

const handleMetamaskError = (err: any) => {
  if (err?.message === 'Unable to detect window.ethereum.') {
    // ignore error
  } else {
    console.error(err)
  }
}

const useMetamask = (net: CarbonSDK.Network): UseMetaMaskResult => {
  const network = CarbonSDK.parseNetwork(net) ?? CarbonSDK.Network.MainNet
  const metamaskInstance = useMemo(() => new MetaMask(network), [network])
  const metamask = useRef<MetaMask>(metamaskInstance)

  useEffect(() => {
    (async () => {
      const metamaskApi = metamaskInstance.getMetaMaskProvider()
      try {
        if (!connectListener) {
          metamaskApi?.on('connect', (connectInfo: unknown) => {
            logger('metamask connect change', connectInfo as ConnectInfo)
            Object.values(connectChangeListeners).forEach((listener) => {
              try {
                listener()
              } catch (err) {
                handleMetamaskError(err)
              }
            })
          })
          connectListener = true
        }

        if (!networkListener) {
          metamaskApi?.on('chainChanged', async (chainId: unknown) => {
            logger('metamask chain change', chainId as string)
            await metamaskInstance.syncBlockchain()
            Object.values(networkChangeListeners).forEach((listener) => {
              try {
                listener()
              } catch (err) {
                handleMetamaskError(err)
              }
            })
          })
          networkListener = true
        }

        if (!walletListener) {
          metamaskApi?.on('accountsChanged', async (accounts: unknown) => {
            logger('metamask account change', accounts as string[])
            if ((accounts as string[])?.length === 0) return
            window.dispatchEvent(new CustomEvent('metamask_accountsChanged', {
              detail: {
                metamaskInstance,
              },
            }))
            await metamaskInstance.syncConnectedAccount()
            Object.values(walletChangeListeners).forEach((listener) => {
              try {
                listener()
              } catch (err) {
                handleMetamaskError(err)
              }
            })
          })
          walletListener = true
        }
      } catch (err) {
        handleMetamaskError(err)
      }
    })()
  }, [metamaskInstance]) // eslint-disable-line
  return { metamask: metamask.current, registerListener, registerListenerWallet }
}

export default useMetamask
