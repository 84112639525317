import dayjs from 'dayjs'
import { Set } from 'immutable'
import { Middleware } from 'redux'

import { StorageKey } from 'js/constants/app'
import { LayoutStructVersion } from 'js/constants/assets'
import { ExchangeActionTypes } from 'js/state/modules/exchange/types'
import baseLayouts from 'js/utils/layouts'
import { safeParseStoredValue } from 'js/utils/localstorage'
import { uuidv4 } from 'js/utils/strings'
import { ChartLayoutsType, ChartLayoutType, HiddenBanners } from 'js/utils/types'

import { DefaultInitialState as AccDefaultInitialState } from '../modules/account/reducer'
import { AccountActionTypes } from '../modules/account/types'
import { DefaultInitialState as AppDefaultInitialState } from '../modules/app/reducer'
import { AppActionTypes, CustomNodeItem } from '../modules/app/types'
import { DefaultInitialState as CompetitionDefaultInitialState } from '../modules/competition/reducer'
import { CompetitionActionTypes } from '../modules/competition/types'
import { DefaultInitialState as ExchangeDefaultInitialState } from '../modules/exchange/reducer'
import { RootState } from '../modules/rootReducer'
import { DefaultInitialState as StatsigDefaultInitialState } from '../modules/statsig/reducer'
import { StatsigActionTypes } from '../modules/statsig/types'

const processNodeArr = (nodeList: any): CustomNodeItem[] => {
  return nodeList.reduce((prev: CustomNodeItem[], node: any) => {
    const newPrev = prev
    const newNode = node
    // if not object, omit from array
    if (!(newNode instanceof Object)) {
      return prev
    }
    // if not old CustomNodeItem, omit from array
    if (!(
      Object.prototype.hasOwnProperty.call(newNode, 'appBuild')
      && Object.prototype.hasOwnProperty.call(newNode, 'moniker')
      && Object.prototype.hasOwnProperty.call(newNode, 'rpcUrl')
      && Object.prototype.hasOwnProperty.call(newNode, 'restUrl')
      && Object.prototype.hasOwnProperty.call(newNode, 'wsUrl')
      && Object.prototype.hasOwnProperty.call(newNode, 'faucetUrl')
      && Object.prototype.hasOwnProperty.call(newNode, 'insightsUrl')
    )) {
      return prev
    }
    // add nodeId, tmWsUrl if not added
    if (!newNode.nodeId) {
      newNode.nodeId = uuidv4()
    }
    if (!newNode.tmWsUrl) {
      newNode.tmWsUrl = ''
    }
    newPrev.push(newNode as CustomNodeItem)
    return newPrev
  }, [])
}

const localStorageMiddleware: Middleware = (store) => (next) => (action) => {
  const { type, payload } = action
  switch (type) {
    case AppActionTypes.SET_THEME_TYPE: {
      localStorage.setItem(AppActionTypes.SET_THEME_TYPE, payload)
      break
    }
    case ExchangeActionTypes.ADD_FAVOURITE_MARKET: {
      const state: RootState = store.getState()
      const { favouriteMarkets } = state.exchange
      localStorage.setItem('@exchange/favourite_markets', JSON.stringify(favouriteMarkets.add(payload)))
      break
    }
    case ExchangeActionTypes.REMOVE_FAVOURITE_MARKET: {
      const state: RootState = store.getState()
      const { favouriteMarkets } = state.exchange
      localStorage.setItem('@exchange/favourite_markets', JSON.stringify(favouriteMarkets.remove(payload)))
      break
    }
    case ExchangeActionTypes.SET_LAYOUT: {
      localStorage.setItem(ExchangeActionTypes.SET_LAYOUT, JSON.stringify(payload))
      break
    }
    case ExchangeActionTypes.SET_LAYOUTS: {
      localStorage.setItem(ExchangeActionTypes.SET_LAYOUTS, JSON.stringify({
        ...payload,
        v: LayoutStructVersion,
      }))
      break
    }
    case ExchangeActionTypes.SET_MARKET_PREFERRED_LEVERAGES: {
      localStorage.setItem(ExchangeActionTypes.SET_MARKET_PREFERRED_LEVERAGES, JSON.stringify(payload))
      break
    }
    case ExchangeActionTypes.RESET_LAYOUT: {
      localStorage.removeItem(ExchangeActionTypes.SET_LAYOUT)
      localStorage.removeItem(ExchangeActionTypes.SET_LAYOUTS)
      const hideLayouts = localStorage.getItem(ExchangeActionTypes.SET_HIDE_LAYOUTS)
      if (hideLayouts) {
        localStorage.setItem(ExchangeActionTypes.SET_HIDE_LAYOUTS, JSON.stringify(Set()))
      }
      break
    }
    case ExchangeActionTypes.SET_HIDE_LAYOUTS: {
      const { layout, hide } = payload
      const state: RootState = store.getState()
      const { hideLayouts } = state.exchange
      if (hide) {
        localStorage.setItem(
          ExchangeActionTypes.SET_HIDE_LAYOUTS, JSON.stringify(hideLayouts.add(layout)),
        )
      } else {
        localStorage.setItem(
          ExchangeActionTypes.SET_HIDE_LAYOUTS, JSON.stringify(hideLayouts.remove(layout)),
        )
      }
      break
    }
    case AppActionTypes.SET_NET: {
      localStorage.setItem(AppActionTypes.SET_NET, JSON.stringify(payload))
      break
    }
    case AppActionTypes.SET_CUSTOM_NODES: {
      localStorage.setItem(AppActionTypes.SET_CUSTOM_NODES, JSON.stringify(payload))
      break
    }
    case AppActionTypes.SET_SELECTED_NODES: {
      localStorage.setItem(AppActionTypes.SET_SELECTED_NODES, JSON.stringify(payload))
      break
    }
    case AppActionTypes.SET_AUTO_SELECT_NODE: {
      localStorage.setItem(AppActionTypes.SET_AUTO_SELECT_NODE, payload)
      break
    }
    case AccountActionTypes.SET_LAST_TRANSACTION_TIMESTAMP: {
      localStorage.setItem(AccountActionTypes.SET_LAST_TRANSACTION_TIMESTAMP, JSON.stringify(payload))
      break
    }
    case AccountActionTypes.SET_GRANTEE_DETAILS: {
      localStorage.setItem(AccountActionTypes.SET_GRANTEE_DETAILS, JSON.stringify(action.payload))
      break
    }
    case AccountActionTypes.SET_SIGNLESS_DIALOG_TRIGGER: {
      const { triggerType, walletAddress } = payload
      if (triggerType) {
        const signlessDialogTriggerTimestampMap = safeParseStoredValue(localStorage.getItem(AccountActionTypes.SET_SIGNLESS_DIALOG_TRIGGER), {})
        const updatedTimestampMapState = {
          ...signlessDialogTriggerTimestampMap,
          [walletAddress]: dayjs.utc().unix(),
        }
        localStorage.setItem(AccountActionTypes.SET_SIGNLESS_DIALOG_TRIGGER, JSON.stringify(updatedTimestampMapState))
      }
      break
    }
    case StatsigActionTypes.SET_STATSIG_EXPERIMENTS: {
      localStorage.setItem(StatsigActionTypes.SET_STATSIG_EXPERIMENTS, JSON.stringify(payload))
      break
    }
  }
  return next(action)
}

export const loadLocalStorageState = (): RootState => {
  const state: Partial<RootState> = {}
  // Exchange states
  const savedFavMarkets = localStorage.getItem('@exchange/favourite_markets')
  let favouriteMarkets: Set<string>
  const parsedFavMarkets = safeParseStoredValue(savedFavMarkets, null)
  if (parsedFavMarkets) {
    favouriteMarkets = Set(parsedFavMarkets)
  } else {
    favouriteMarkets = Set()
  }

  const savedLayout = localStorage.getItem(ExchangeActionTypes.SET_LAYOUT)
  let layout: ChartLayoutType
  layout = safeParseStoredValue(savedLayout, baseLayouts.defaultLayout)
  const savedLayouts = localStorage.getItem(ExchangeActionTypes.SET_LAYOUTS)
  let layouts: ChartLayoutsType
  if (savedLayouts) {
    layouts = safeParseStoredValue(savedLayouts, null)
    if (LayoutStructVersion > (layouts?.v || 0)) {
      layouts = null
      layout = null
    }
  }

  const savedHideLayouts = localStorage.getItem(ExchangeActionTypes.SET_HIDE_LAYOUTS)
  const parsedSavedHideLayouts = safeParseStoredValue(savedHideLayouts, null)
  let hideLayouts: Set<string>
  if (layouts && layout && parsedSavedHideLayouts) {
    hideLayouts = Set(parsedSavedHideLayouts)
  } else {
    hideLayouts = Set()
  }

  if (!layouts) {
    layouts = {
      v: LayoutStructVersion,
      xl: baseLayouts.xlLayout,
      lg: baseLayouts.lgLayout,
      md: baseLayouts.mdLayout,
      sm: baseLayouts.smLayout,
      xs: baseLayouts.xsLayout,
    }
  }
  if (!layout) {
    layout = baseLayouts.defaultLayout
  }

  const savedSearchHistory = localStorage.getItem(ExchangeActionTypes.SET_SEARCH_HISTORY)
  const searchHistory = safeParseStoredValue(savedSearchHistory, {})

  state.exchange = ExchangeDefaultInitialState({
    favouriteMarkets,
    layout,
    layouts,
    hideLayouts,
    searchHistory,
  })

  // App states
  let rawThemeType: string | null = localStorage.getItem(AppActionTypes.SET_THEME_TYPE)
  const themeType: 'dark' | 'light' = (rawThemeType !== 'dark' && rawThemeType !== 'light') ? 'dark' : rawThemeType

  const nodeFromLocal = safeParseStoredValue(localStorage.getItem(AppActionTypes.SET_CUSTOM_NODES), [])
  let customNodes: CustomNodeItem[] = []
  if (Array.isArray(nodeFromLocal) && nodeFromLocal.length > 0) {
    customNodes = processNodeArr(nodeFromLocal)
  }
  localStorage.setItem(AppActionTypes.SET_CUSTOM_NODES, JSON.stringify(customNodes))

  const selectedNodes = safeParseStoredValue(localStorage.getItem(AppActionTypes.SET_SELECTED_NODES), {})

  const autoSelectNode = localStorage.getItem(AppActionTypes.SET_AUTO_SELECT_NODE)

  const disableConfetti = localStorage.getItem(AppActionTypes.SET_DISABLE_CONFETTI) === 'true'

  state.app = AppDefaultInitialState({
    themeType,
    customNodes,
    selectedNodes,
    autoSelectNode: autoSelectNode === 'true',
    disableConfetti,
  })

  const granteeDetailsMapStr = localStorage.getItem(AccountActionTypes.SET_GRANTEE_DETAILS)
  const granteeDetailsMap = safeParseStoredValue(granteeDetailsMapStr) ?? {}

  state.account = AccDefaultInitialState({ granteeDetailsMap })

  const hideDemexPointsGuide = localStorage.getItem(CompetitionActionTypes.SET_HIDE_DEMEX_POINTS_GUIDE)
  const hideDemexPointsGuideArr = safeParseStoredValue(hideDemexPointsGuide, [])

  state.competition = CompetitionDefaultInitialState({
    hideDemexPointsGuide: hideDemexPointsGuideArr
  })

  const statsigExperimentMapJSON = localStorage.getItem(StatsigActionTypes.SET_STATSIG_EXPERIMENTS)
  const statsigExperimentMap = safeParseStoredValue(statsigExperimentMapJSON)
  state.statsig = StatsigDefaultInitialState({
    statsigExperiments: statsigExperimentMap
  })

  try {
    const hiddenBanners: HiddenBanners = JSON.parse(localStorage.getItem(StorageKey.HiddenBannerKey) || '{}')
    Object.entries(hiddenBanners).forEach(([bannerId, hiddenBanner]) => {
      if (typeof hiddenBanner?.expiry === "number" && hiddenBanner.expiry < new Date().getTime()) {
        delete hiddenBanners[bannerId]
      }
    })

    if (hiddenBanners) {
      state.app = state.app.set('hiddenBanners', hiddenBanners)
    }
  } catch {
    // Ignore load error
  }

  const latestChain = localStorage.getItem(AppActionTypes.SET_CURRENT_CHAIN)
  state.app  = state.app.set('currentChain', latestChain !== '' ? (latestChain ?? 'Mantle') : '')

  return state as RootState
}

export default localStorageMiddleware
