import { BigNumber } from 'bignumber.js'
import { BlockchainUtils, CarbonSDK, Insights, TypeUtils } from 'carbon-js-sdk'
import { Bridge } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/bridge'
import { TokenGroupDetails } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/group'
import { Token, TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { DenomTrace } from 'carbon-js-sdk/lib/codec/ibc/applications/transfer/v1/transfer'
import { ExtendedChainInfo } from 'carbon-js-sdk/lib/constant/ibc' // eslint-disable-line import/no-unresolved
import { StoredMnemonicInfo } from 'carbon-js-sdk/lib/provider/metamask/MetaMask' // eslint-disable-line import/no-unresolved
import { providers } from 'ethers'
import { List, RecordOf } from 'immutable'
import { AnyAction } from 'redux'

import { AdditionalIbcToken, AdditionalIbcTokenConfigItem, ExternalChainChannelMap } from 'js/constants/TransferOptions'
import { OracleInfo } from 'js/constants/chart'
import { FeeDropDownItem } from 'js/constants/fees'
import { NodeFormState, RatingLatencyObj } from 'js/utils/nodes'
import { HiddenBanners, MarketOverride, SimpleMap } from 'js/utils/types'

import ReferralClient from './helpers/ReferralClient'

export interface AppStateProps {
  readonly subPage: Page
  readonly debugMode: boolean
  readonly walletToggle: boolean
  readonly themeType: 'dark' | 'light'
  readonly carbonSDK?: CarbonSDK
  readonly userMnemonic?: string
  readonly connectedAgent?: string
  readonly isRainbowKit?: boolean
  readonly tokens: Token[]
  readonly newTokenDenoms: Set<string>
  readonly ledgerSigningType: LedgerSigningType | undefined
  readonly signError?: string
  readonly windowActive: boolean
  readonly blockHeight: number
  readonly blocksMoving: boolean
  readonly showEditLeverageDialog: boolean,
  readonly hideConvertDialog: string[],
  readonly openMenu: boolean,
  readonly openMoreMenu: boolean,
  readonly openNotificationMenu: boolean,
  readonly successMsgBlacklist: SuccessBlacklistObj,
  readonly hideSuccess: SuccessBlacklistObj,
  readonly openDisplayMenu: boolean,
  readonly openHelpMenu: boolean,
  readonly openNodeMenu: boolean,
  readonly nodes: Insights.NodeItem[],
  readonly isShowNodeInfoForm: boolean,
  readonly customNodes: CustomNodeItem[],
  readonly selectedNodes?: SimpleMap<any>,
  readonly autoSelectNode: boolean,
  readonly formNode?: NodeFormState,
  readonly connectError?: Error,
  readonly internetConnected: boolean,
  readonly bridges: Bridge[],
  readonly latency: RatingLatencyObj,
  readonly gasPricesMap: MinGasPricesMap,
  readonly cachedWalletLoading: boolean
  readonly demexConfig: DemexConfig
  readonly showMobilePromo: boolean,
  readonly groupTokensMap: TypeUtils.SimpleMap<GroupTokenDetails>,
  readonly currentFeeSettingsDropdown: FeeDropDownItem | null,

  readonly chainInfoMap: TypeUtils.SimpleMap<ExtendedChainInfo>,
  readonly errorChainInfoMap: string,
  readonly denomTraces: TypeUtils.SimpleMap<DenomTrace>,
  readonly additionalIbcTokens?: AdditionalIbcTokenList,

  // used to invalidate sessionStorage cached chainInfoMap
  // when denom traces changes
  readonly denomTracesHash: string | null,

  // confetti animation
  readonly confettiState: boolean,
  readonly disableConfetti: boolean,
  readonly loggingIn: boolean,
  readonly promoRegistered: boolean,
  readonly legacyAccounts: StoredMnemonicInfo[],
  readonly isLegacyLogin: boolean,
  readonly showActivateAccountDialog: boolean,
  readonly activateAccountTxStatus: TransactionStatus,
  readonly subsequentTxStatus: TransactionStatus,
  readonly thirdPartyAPIStatuses: ThirdPartyAPIStatuses,
  readonly referralClient?: ReferralClient,
  readonly feeOpen: boolean
  readonly showFeedbackBanner: boolean,
  readonly pwaInstallPrompt: PWABeforeInstallPromptEvent | null,
  readonly openPwaInstallDialog: boolean,
  readonly showPromoBanner: boolean,
  readonly showSignlessSettingsDialog: boolean,
  readonly oracleInfo: OracleInfo[],
  readonly queuePwaUpdateToast: boolean,
  readonly hiddenBanners: HiddenBanners,
  readonly hiddenTypeformWidgets: string[],

  readonly appVersion: string,

  readonly ethersRPCProviders?: EthersRPCProviders,
  readonly currentChain: string,

  readonly authDialog?: AuthDialogProps
  readonly showDemexLoader: boolean,
}

export interface EthersRPCProviders {
  readonly rpcProvider: providers.JsonRpcProvider,
  readonly batchProvider: providers.JsonRpcBatchProvider,
}

export type AdditionalIbcTokenList = List<AdditionalIbcToken>

export type AppState = RecordOf<AppStateProps>

export const AppActionTypes = {
  INIT_CHAININFO_MAP: '@app/INIT_CHAININFO_MAP',
  SET_SUB_PAGE: '@app/SET_SUB_PAGE',
  SET_WALLET_TOGGLE: '@app/SET_WALLET_TOGGLE',
  SET_THEME_TYPE: '@app/SET_THEME_TYPE',
  SET_WINDOW_ACTIVE: '@app/SET_WINDOW_ACTIVE',
  SET_NET: '@app/SET_NET',
  SET_TOKENS: '@app/SET_TOKENS',
  SET_NEW_TOKEN_DENOMS: '@app/SET_NEW_TOKEN_DENOMS',
  SET_CARBON_SDK: '@app/SET_CARBON_SDK',
  NETWORK_CHANGED: '@app/NETWORK_CHANGED',
  FETCH_TOKENS: '@app/FETCH_TOKENS',
  SET_TXNS_FEES: '@app/SET_TXNS_FEES',
  FETCH_TXNS_FEES: '@app/FETCH_TXNS_FEES',
  REQUEST_SIGN: '@app/REQUEST_SIGN',
  FINISH_SIGN: '@app/FINISH_SIGN',
  UPDATE_BLOCK_HEIGHT: '@app/UPDATE_BLOCK_HEIGHT',
  RESET_BLOCK_HEIGHT: '@app/RESET_BLOCK_HEIGHT',
  UPDATE_BLOCKS_MOVING: '@app/UPDATE_BLOCKS_MOVING',
  SET_SHOW_EDIT_LEVERAGE_DIALOG: '@app/SET_SHOW_EDIT_LEVERAGE_DIALOG',
  SET_MOBILE_MENU_OPEN: '@app/SET_MOBILE_MENU_OPEN',
  SET_MOBILE_MENU_CLOSE: '@app/SET_MOBILE_MENU_CLOSE',
  SET_MOBILE_MORE_MENU_OPEN: '@app/SET_MOBILE_MORE_MENU_OPEN',
  SET_MOBILE_MORE_MENU_CLOSE: '@app/SET_MOBILE_MORE_MENU_CLOSE',
  SET_MOBILE_NOTIFICATION_MENU_OPEN: '@app/SET_MOBILE_NOTIFICATION_MENU_OPEN',
  SET_MOBILE_NOTIFICATION_MENU_CLOSE: '@app/SET_MOBILE_NOTIFICATION_MENU_CLOSE',
  SET_ALL_MENUS_CLOSE: '@app/SET_ALL_MENUS_CLOSE',
  SET_WALLET_DROPDOWN_CLOSE: '@app/SET_WALLET_DROPDOWN_CLOSE',
  SET_WALLET_DROPDOWN_OPEN: '@app/SET_WALLET_DROPDOWN_OPEN',
  SET_FEE_TOGGLE_OPEN: '@app/SET_FEE_TOGGLE_OPEN',
  SET_FEE_TOGGLE_CLOSE: '@app/SET_FEE_TOGGLE_CLOSE',
  CLEAR_DROPDOWNS: '@app/CLEAR_DROPDOWNS',
  CLEAR_CONNECTED_WALLET: '@app/CLEAR_CONNECTED_WALLET',
  SET_SUCCESS_MSG_BLACKLIST: '@app/SET_SUCCESS_MSG_BLACKLIST',
  SET_HIDE_SUCCESS_MSGS: '@app/SET_HIDE_SUCCESS_MSGS',
  SET_DISPLAY_MENU_OPEN: '@app/SET_DISPLAY_MENU_OPEN',
  SET_DISPLAY_MENU_CLOSE: '@app/SET_DISPLAY_MENU_CLOSE',
  SET_HELP_MENU_OPEN: '@app/SET_HELP_MENU_OPEN',
  SET_HELP_MENU_CLOSE: '@app/SET_HELP_MENU_CLOSE',
  SET_NODE_MENU_OPEN: '@app/SET_NODE_MENU_OPEN',
  SET_NODE_MENU_CLOSE: '@app/SET_NODE_MENU_CLOSE',
  SET_NODES: '@app/SET_NODES',
  SHOW_NODE_INFO_FORM: '@app/SHOW_NODE_INFO_FORM',
  HIDE_NODE_INFO_FORM: '@app/HIDE_NODE_INFO_FORM',
  SET_CUSTOM_NODES: '@app/SET_CUSTOM_NODES',
  SET_SELECTED_NODES: '@app/SET_SELECTED_NODES',
  SET_AUTO_SELECT_NODE: '@app/SET_AUTO_SELECT_NODE',
  SET_FORM_NODE: '@app/SET_FORM_NODE',
  SET_CONNECT_ERROR: '@app/SET_CONNECT_ERROR',
  SET_INTERNET_CONNECTED: '@app/SET_INTERNET_CONNECTED',
  SET_ALL_BRIDGES: '@app/SET_ALL_BRIDGES',
  SET_RATING_LATENCY: '@app/SET_RATING_LATENCY',
  SET_MIN_GAS_PRICES: '@app/SET_MIN_GAS_PRICES',
  SET_MCAP_MAP: '@app/SET_MCAP_MAP',
  SET_CACHED_WALLET_LOADING: '@app/SET_CACHED_WALLET_LOADING',
  SET_DEMEX_CONFIG: '@app/SET_DEMEX_CONFIG',
  SET_SHOW_MOBILE_PROMO: '@app/SET_SHOW_MOBILE_PROMO',
  SET_HIDE_CONVERT_DIALOG: '@app/SET_HIDE_CONVERT_DIALOG',
  SET_GROUP_TOKENS_MAP: '@app/SET_GROUP_TOKENS_MAP',
  SET_CHAIN_INFO_MAP: '@app/SET_CHAIN_INFO_MAP',
  QUERY_CHAIN_INFO_MAP: '@app/QUERY_CHAIN_INFO_MAP',
  SET_ERROR_CHAIN_INFO_MAP: '@app/SET_ERROR_CHAIN_INFO_MAP',
  SET_IBC_DENOM_TRACES: '@app/SET_IBC_DENOM_TRACES',
  SET_ADDITIONAL_IBC_TOKENS: '@app/SET_ADDITIONAL_IBC_TOKENS',
  SET_CONFETTI_STATE: '@app/SET_CONFETTI_STATE',
  SET_DISABLE_CONFETTI: '@app/SET_DISABLE_CONFETTI',
  SET_LOGGING_IN: '@app/SET_LOGGING_IN',
  SET_PROMO_REGISTERED: '@app/SET_PROMO_REGISTERED',
  QUERY_PROMO_REGISTERED: '@app/QUERY_PROMO_REGISTERED',
  SET_LEGACY_ACCOUNTS: '@app/SET_LEGACY_ACCOUNTS',
  SET_LEGACY_LOGIN: '@app/SET_LEGACY_LOGIN',
  SET_SHOW_CREATE_ACCOUNT_DIALOG: '@app/SET_SHOW_CREATE_ACCOUNT_DIALOG',
  SET_ACTIVATE_ACCOUNT_TX_STATUS: '@app/SET_ACTIVATE_ACCOUNT_TX_STATUS',
  SET_SUBSEQUENT_TX_STATUS: '@app/SET_SUBSEQUENT_TX_STATUS',
  RESET_ACTIVATE_ACCOUNT_STATUSES: '@app/RESET_ACTIVATE_ACCOUNT_STATUSES',
  SET_THIRD_PARTY_API_STATUSES: '@app/SET_THIRD_PARTY_API_STATUSES',
  SET_REFERRAL_CLIENT: '@app/SET_REFERRAL_CLIENT',
  SET_FEEDBACK_BANNER: '@app/SET_FEEDBACK_BANNER',
  SET_PWA_INSTALL_PROMPT: '@app/SET_PWA_INSTALL_PROMPT',
  OPEN_PWA_INSTALL_DIALOG: '@app/OPEN_PWA_INSTALL_DIALOG',
  SET_PROMO_BANNER: '@app/SET_PROMO_BANNER',
  SET_ORACLE_INFO: '@app/SET_ORACLE_INFO',
  QUEUE_PWA_UPDATE_TOAST: '@app/QUEUE_PWA_UPDATE_TOAST',
  ADD_HIDDEN_BANNER: '@app/ADD_HIDDEN_BANNER',
  SUBSCRIBE_TO_PUSH_NOTIFICATIONS: '@app/SUBSCRIBE_TO_PUSH_NOTIFICATIONS',
  SET_FEE_SETTINGS_DROPDOWN: '@app/SET_FEE_SETTINGS_DROPDOWN',
  ADDRESS_CHANGED: '@app/ADDRESS_CHANGED',
  TRADE_MODE_GUIDE_DIALOG: '@app/TRADE_MODE_GUIDE_DIALOG',
  SET_HIDE_TYPEFORM_WIDGET: '@app/SET_HIDE_TYPEFORM_WIDGET',
  SET_HIDDEN_TYPEFORM_WIDGETS_ARRAY: '@app/SET_HIDDEN_TYPEFORM_WIDGETS_ARRAY',
  SET_APP_VERSION: '@app/SET_APP_VERSION',
  RESET_SHOW_APP_LOADING: '@app/RESET_SHOW_APP_LOADING',
  SET_ETHERS_RPC_PROVIDERS: '@app/SET_ETHERS_RPC_PROVIDERS',
  SET_CURRENT_CHAIN: '@app/SET_CURRENT_CHAIN',
  TOGGLE_AUTH_DIALOG: '@app/TOGGLE_AUTH_DIALOG',
  UPDATE_AUTH_DIALOG: '@app/UPDATE_AUTH_DIALOG',
  SET_SHOW_DEMEX_LOADER: '@app/SET_SHOW_DEMEX_LOADER',
}

export enum LedgerSigningType {
  TRANSACTION = 'transaction',
  AUTH = 'auth'
}
export interface PWABeforeInstallPromptEvent extends Event {
  prompt(): Promise<UserChoice>,
}
interface UserChoice {
platform: string,
  outcome: string
}

export const AppTaskNames: { [index: string]: string } = {
USDPrices: 'usd_prices_app',
}

export interface SuccessBlacklistObj {
  [key: string]: string[] // key: address, value: array of tx ids
}

export interface SuccessBlacklistProps {
  address: string
  txHash: string | string[]
}

export interface CustomNodeItem {
  appBuild: CarbonSDK.Network
  nodeId: string
  moniker: string
  rpcUrl: string
  restUrl: string
  wsUrl: string
  faucetUrl: string
  insightsUrl: string
  tmWsUrl: string
}

export enum Page {
  Ledger = 'ledger',
  Keplr = 'keplr',
  Leap = 'leap',
  Metamask = 'metamask',
  EncryptedKey = 'encryptedKey',
  Register = 'register',
  Reset = 'reset',
  Social = 'social',
  Main = 'main',
}

export interface DemexPointsConfig {
  depositsPerSpin: number,
  tradingVolumePerSpin: number,
}

export interface MinGasPricesMap {
  [key: string]: BigNumber
}

export interface PerpPoolPromo {
  start: string,
  end: string,
  perpPoolDepositBoost: number
  perpTradingBoost: number
}

export interface TypeformWidgetConfig {
  message: string
  surveyLink: string
  endTime: string
  pages: string[]
}

export interface DemexConfig {
  network: CarbonSDK.Network
  maintenance?: {
    title?: string
    message?: string
  },
  prelaunchMarkets: string[]
  blacklistedMarkets: string[]
  blacklistedTokens: string[]
  blacklistedPools: number[]
  transferOptions: { [key in BlockchainUtils.BlockchainV2]: number }
  networkFees: SimpleMap<number>
  perpPoolPieChartColors: SimpleMap<string>
  marketsOverride?: SimpleMap<MarketOverride>
  marketsSpotHistory: SimpleMap<string>
  demexPointsConfig: DemexPointsConfig
  perpPoolPromo: SimpleMap<PerpPoolPromo>
  crossSellingSourceTokens: string[]
  typeformWidgetConfig?: TypeformWidgetConfig[]
  externalChainChannels: ExternalChainChannelMap
  additionalIbcTokenConfig: AdditionalIbcTokenConfigItem[]
  demexTradingLeagueConfig?: DemexTradingLeagueConfig
  nitronPromoTokens: string[] | null
  nitronPromoLabel: string | null
  perpPoolConfig: PerpPoolConfig
  wSWTHContract: string
}

export interface DemexTradingLeagueConfig {
  promoMarkets: string[]
  currentPrizeSymbol: string
  currentCompPerpPoolId: number
}

export interface JsonPerpPoolBanner {
  perp_pool_id: string
  show_from?: string
  show_until?: string
  title: string
  removed_markets?: string
  added_markets?: string
  subtext?: string
  action_trigger_date?: string
  past_tense_text?: string
}

export interface PerpPoolBanner {
  perpPoolId: number
  showFrom?: string
  showUntil?: string
  title: string
  removedMarkets?: string
  addedMarkets?: string
  subText?: string
  actionTriggerDate?: string
  pastTenseText?: string
}

export interface GroupTokenDetails extends TokenGroupDetails {
  registeredDenoms: string[]
  activeSymbols: string[]
  balances?: TokenBalance[]
}

export enum TransactionStatus {
  None = 'none',
  Ongoing = 'ongoing',
  Success = 'success',
  Fail = 'fail'
}

export interface ThirdPartyAPIStatuses {
  isGithubDown: boolean,
  isCoinGeckoDown: boolean,
  isCarbonReferralDown: boolean,
  isCarbonInsightsDown: boolean,
  isHydrogenDown: boolean,
}

export enum APICategory {
  Insights = "insights",
  Hydrogen = "hydrogen",
  Github = "github",
  CarbonReferral = 'carbonReferral',
  CoinGecko = 'coinGecko'
}

export interface PerpPoolConfig {
  incentives: {
    proxy: string,
    distributors: string[],
  },
  banners: PerpPoolBanner[]
}

export enum DialogCategory {
  NoToken = "no-token",
  TokenExpired = "token-expired",
}

export interface AuthDialogProps {
  open: boolean
  dialogCategory?: DialogCategory
  retry?: AnyAction
  revert?: AnyAction
  error?: Error
}
