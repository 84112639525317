import { BlockchainUtils, CarbonSDK, CarbonSignerTypes, IBCUtils, Models } from 'carbon-js-sdk'
import { ExternalTokenMapping } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bridge/bridge'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAccount } from 'wagmi'

import { AdditionalIbcToken, tokenStandards, TransferChannel, TransferKey, TransferMethod, TransferType } from 'js/constants/TransferOptions'
import { GroupOptionDefaultChain } from 'js/constants/assets'
import { useSelect, useTaskSubscriber } from 'js/hooks'
import { getAdditionalIbcTokens, getCarbonSDK, getDemexConfig, getDenomTraces, getGroupTokens } from 'js/state/modules/app/selectors'
import { GroupTokenDetails } from 'js/state/modules/app/types'
import { getBridgeConnections } from 'js/state/modules/bridge/selectors'
import { getTokenPairsMap } from 'js/state/modules/erc20/selectors'
import { RootState } from 'js/state/modules/rootReducer'
import { getSelectedToken } from 'js/state/modules/walletBalance/selectors'
import { chainLabel, SimpleMap, supportedCrosschainsNames } from 'js/utils'
import { generateTransferOptionData } from 'js/utils/externalTransfer'

import useCurrentWalletConnector from './useCurrentWalletConnector'

export interface OptionItem {
  blockchain: BlockchainUtils.BlockchainV2
  title: string
  channels: TransferChannel[]
}

const signerTypesMap: { [key in TransferMethod]: CarbonSignerTypes | undefined } = {
  [TransferMethod.Token]: CarbonSignerTypes.PrivateKey,
  [TransferMethod.Ledger]: CarbonSignerTypes.Ledger,
  [TransferMethod.MetaMask]: CarbonSignerTypes.PrivateKey,
  [TransferMethod.O3Wallet]: CarbonSignerTypes.PublicKey,
  [TransferMethod.ZilPay]: CarbonSignerTypes.PrivateKey,
  [TransferMethod.IBC]: CarbonSignerTypes.PrivateKey,
  [TransferMethod.IBCLeap]: CarbonSignerTypes.BrowserInjected,
  [TransferMethod.IBCKeplr]: CarbonSignerTypes.BrowserInjected,
  [TransferMethod.TradeHub]: undefined,
  [TransferMethod.Bridge]: undefined,
  [TransferMethod.PF]: undefined,
  [TransferMethod.AxelarMetamask]: CarbonSignerTypes.PrivateKey,
  [TransferMethod.Rainbowkit]: undefined,
}

export const nonEvmTransferOptions = [TransferMethod.Token]
export const evmTransferOptions = [TransferMethod.MetaMask, TransferMethod.Token]
export const customTransferOptions: { [key: string]: TransferMethod[] } = {
  Ethereum: [TransferMethod.Ledger],
  Neo3: [TransferMethod.O3Wallet, TransferMethod.Ledger],
  Native: [TransferMethod.TradeHub],
  Zilliqa: [TransferMethod.ZilPay],
}

const disableSmartWallet: BlockchainUtils.BlockchainV2[] = [
  'Arbitrum',
  'Polygon',
  'OKC',
  'Neo3',
  'Zilliqa',
  'Mantle',
  'OP',
  'Base'
]
const disableLeap: BlockchainUtils.BlockchainV2[] = [
  'Canto',
  'Evmos',
]

const blockchainDepositTransferOptions = [
  TransferMethod.MetaMask,
  TransferMethod.O3Wallet,
  TransferMethod.IBCKeplr,
  TransferMethod.IBCLeap,
  TransferMethod.ZilPay,
]

export const getPolynetworkTransferMethods = (
  blockchain: BlockchainUtils.BlockchainV2,
  transferType: TransferType = TransferType.Deposit,
  sdk?: CarbonSDK,
): TransferMethod[] => {
  if (!sdk) return []

  const defaultMethods = (BlockchainUtils.isEvmChain(blockchain) ? evmTransferOptions : nonEvmTransferOptions)
  let completeMethods = defaultMethods.concat(customTransferOptions[blockchain] ?? [])
  if (blockchain === 'Neo') {
    completeMethods = [TransferMethod.O3Wallet, TransferMethod.Token, TransferMethod.Ledger]
  }

  if (transferType === TransferType.Deposit) {
    // Remove token transfer option (i.e. smart wallet) if
    // (1) blockchain is included in disableSmartWallet
    // (2) no mnemonics/privatekey
    if (disableSmartWallet.includes(blockchain) || !sdk.wallet?.isSmartWalletEnabled()) {
      completeMethods = completeMethods.filter((method: TransferMethod) => method !== TransferMethod.Token)
    }

    // if network !== Mainnet, filter out O3 wallet option
    if (sdk?.network !== CarbonSDK.Network.MainNet) {
      completeMethods = completeMethods.filter((method: TransferMethod) => method !== TransferMethod.O3Wallet)
    }
  } else {
    completeMethods = completeMethods.filter((method: TransferMethod) => !blockchainDepositTransferOptions.includes(method))
  }
  return completeMethods
}

export const getIbcTransferMethods = (
  blockchain: BlockchainUtils.BlockchainV2,
  transferType: TransferType = TransferType.Deposit,
) => {
  let completeMethods = transferType === TransferType.Deposit ? [TransferMethod.IBCKeplr, TransferMethod.IBCLeap] : [TransferMethod.IBC]
  if (disableLeap.includes(blockchain)) {
    completeMethods = completeMethods.filter((method: TransferMethod) => method !== TransferMethod.IBCLeap)
  }
  return completeMethods
}

export const getAxelarTransferMethods = () => {
  return [TransferMethod.AxelarMetamask]
}

const useTransferOptions = (denom?: string, transferType?: TransferType): OptionItem[] => {
  const sdk = useSelector(getCarbonSDK)
  const denomTraces = useSelector(getDenomTraces)
  const tokens = useSelector<RootState, Models.Carbon.Coin.Token[]>((state) => state.app.tokens)
  const groupTokenOption = useSelector<RootState, string>((state) => state.walletBalance.groupTokenOption)
  const selectedToken = useSelector(getSelectedToken)
  const { isConnected: isConnectedRainBowkit } = useAccount()
  const connector = useCurrentWalletConnector()
  const additionalIbcTokens = useSelector(getAdditionalIbcTokens)
  const groupDetails = useSelect<GroupTokenDetails | undefined>((state) => getGroupTokens(state)[denom ?? ''])
  const externalToken = useSelect<ExternalTokenMapping | undefined>((state) => state.bridge.externalTokens[denom ?? ''])
  const bridgeConnections = useSelector(getBridgeConnections)
  const demexConfig = useSelector(getDemexConfig)
  const tokenPairsMap = useSelector(getTokenPairsMap)
  const [grpTknLoading] = useTaskSubscriber('runQueryGroupTokens')
  const [additionalTokenInfoLoading] = useTaskSubscriber('runFetchAdditionalTokenInfo')
  const [denomTracesLoading] = useTaskSubscriber('runQueryDenomTraces')

  const loading = grpTknLoading || additionalTokenInfoLoading || denomTracesLoading

  const {
    isGroupToken,
    registeredGroupTokens,
  } = useMemo(() => {
    const initGroupedTokens: Models.Carbon.Coin.Token[] = []
    if (!denom) {
      return {
        isGroupToken: false,
        registeredGroupTokens: initGroupedTokens,
      }
    }

    const isGroupToken = Boolean(groupDetails)
    if (isGroupToken) {
      const registeredGroupTokens = tokens.filter((tkn) => tkn.symbol === groupTokenOption && groupDetails?.registeredDenoms?.includes(tkn.denom))
      return {
        isGroupToken,
        registeredGroupTokens,
      }
    }

    return {
      isGroupToken,
      registeredGroupTokens: initGroupedTokens,
    }
  }, [denom, groupDetails, groupTokenOption, tokens])

  const wrappedTokens: Models.Carbon.Coin.Token[] = useMemo(() => {
    if (!sdk || !denom) return []
    let wrappedTokenArr: Models.Carbon.Coin.Token[] = []
    if (isGroupToken) {
      registeredGroupTokens.forEach((tkn) => {
        const wrappedToken = sdk.token.getWrappedTokens(tkn.denom)
        wrappedTokenArr.push(...wrappedToken)
      })
    } else {
      wrappedTokenArr = sdk.token.getWrappedTokens(denom)
    }

    return wrappedTokenArr
  }, [sdk, denom, isGroupToken, registeredGroupTokens])

  const transferOptionsArr = useMemo(() => {
    const transferOptions: TransferChannel[] = []

    if (!denom || !sdk?.token || !Object.keys(sdk?.token.tokens).length || loading || !additionalIbcTokens) return []

    if (isGroupToken && registeredGroupTokens.length === 0 && groupTokenOption !== selectedToken?.symbol) return []

    const denomTrace = Object.values(denomTraces).find((d) => denom === IBCUtils.makeIBCMinimalDenom(d.path, d.baseDenom))
    const denomTracePathArr = denomTrace?.path.split('/')
    const tokenOriginalChain = sdk.token.getBlockchainV2(denom)

    const initPossibleChains = tokenOriginalChain ? new Set([tokenOriginalChain]) : new Set([])
    const possibleChains = wrappedTokens.reduce((chains, token) => {
      const blockchain = sdk?.token.getBlockchainV2(token.denom)

      if (blockchain && !chains.has(blockchain)) {
        chains.add(blockchain)
      }

      return chains
    }, initPossibleChains)

    const ibcBridges = sdk?.token.bridges.ibc ?? []

    for (const bridge of ibcBridges) {
      if (possibleChains.has(bridge.chainName)) continue

      if (denomTrace && denomTracePathArr) {
        // other token deposit ibc chains
        const srcChannel = denomTracePathArr?.[1]
        if (typeof srcChannel === 'string' && srcChannel === bridge.channels.src_channel) {
          possibleChains.add(bridge.chainName)
          break
        }
      }
    }

    if (externalToken) {
      const connection = bridgeConnections[externalToken.connectionId]
      const chainName = connection?.chainDisplayName
      if (chainName && !possibleChains.has(chainName)) {
        possibleChains.add(chainName)
      }
    }

    const groupedTokenDenoms: SimpleMap<string> = {}

    additionalIbcTokens.forEach((addIbcToken: AdditionalIbcToken) => {
      const isToAddToken = isGroupToken
        ? registeredGroupTokens.find((groupToken) => groupToken.denom === addIbcToken.carbonRecognisedDenom)
        : addIbcToken.carbonRecognisedDenom === denom
      if (!possibleChains.has(addIbcToken.srcChainInfo.chainName) && isToAddToken) {
        possibleChains.add(addIbcToken.srcChainInfo.chainName)

        if (isGroupToken) {
          groupedTokenDenoms[addIbcToken.srcChainInfo.chainName] = addIbcToken.appCurrency.coinMinimalDenom
        }
      }
    })

    if (isGroupToken) {
      registeredGroupTokens.forEach((token) => {
        const blockchain = sdk?.token.getBlockchainV2(token.denom)
        if (blockchain && !possibleChains.has(blockchain)) {
          possibleChains.add(blockchain)
        }
        if (!groupedTokenDenoms[blockchain as string]) {
          groupedTokenDenoms[blockchain as string] = token.denom
        }
      })
    }

    const ibcChains = sdk?.token.getIbcBlockchainNames()
    const axelarChains = sdk?.token.getAxelarBlockchainNames()

    possibleChains.forEach((blockchain) => {
      if (blockchain === 'Native') return
      const chainName = chainLabel(blockchain)
      const tokenType = tokenStandards[blockchain]

      let transferMethodArr = ibcChains.includes(blockchain)
        ? getIbcTransferMethods(blockchain, transferType)
        : getPolynetworkTransferMethods(blockchain, transferType, sdk)
      if (axelarChains.includes(blockchain)) {
        transferMethodArr = getAxelarTransferMethods()
      }

      if (isConnectedRainBowkit && connector?.name && supportedCrosschainsNames.includes(blockchain) && transferType !== TransferType.Withdraw) {
        const tokenTransferOption = generateTransferOptionData(blockchain!, TransferMethod.Rainbowkit, TransferKey.Rainbowkit)
        const chainName = chainLabel(blockchain)
        transferOptions.push({
          ...tokenTransferOption,
          label: `${chainName} (via ${connector.name})`,
          denom: groupedTokenDenoms[blockchain] ?? denom,
        })
      }

      transferMethodArr.forEach((methodType) => {
        if (connector?.name && methodType === TransferMethod.MetaMask) return

        const isAxelar = methodType === TransferMethod.AxelarMetamask

        let label = `${chainName} (via ${methodType})`
        if (isAxelar) {
          label = transferType === TransferType.Deposit ? `${chainName} (via Metamask)` : `${chainName} (via Axelar)`
        }
        if (methodType === TransferMethod.IBCKeplr) label = `${chainName} (via Keplr)`
        if (methodType === TransferMethod.IBCLeap) label = `${chainName} (via Leap)`
        if (methodType === TransferMethod.TradeHub) label = 'Internal Transfer'

        const keySuffix = methodType === TransferMethod.Token ? tokenType?.toLowerCase() : methodType.replace(/\s/g, '').replace('Keplr', '')?.toLowerCase() // return ibcleap, ibc, metamask, o3wallet, etc...
        const keyPrefix = blockchain
        const isIbc = keySuffix === 'ibc' || keySuffix === 'ibcleap'


        let key = `${keyPrefix}-${keySuffix}` as TransferKey
        if (isAxelar) {
          key = TransferKey.AxelarBridge
        } else if (isIbc) {
          key = keySuffix as TransferKey
        }

        const methodDenom = groupedTokenDenoms[blockchain] ?? denom
        const additionalTokenInfo = additionalIbcTokens.find((addIbcToken: AdditionalIbcToken) => (addIbcToken.appCurrency.coinMinimalDenom === methodDenom || addIbcToken.carbonRecognisedDenom === methodDenom) && addIbcToken.srcChainInfo.chainName === blockchain)

        const method: TransferChannel = {
          key,
          transferMethod: methodType,
          blockchain,
          defaultForSigner: signerTypesMap[methodType],
          label,
          tokenType: tokenType ?? chainName,
          isIbc,
          denom: methodDenom,

          additionalTokenInfo,
        }
        transferOptions.push(method)
      })
    })

    // Add Carbon Withdraw option
    const TradehubTransferOption = generateTransferOptionData('Native', TransferMethod.TradeHub, TransferKey.TradeHub)
    if (!registeredGroupTokens?.length) {
      transferOptions.push({
        ...TradehubTransferOption,
        label: 'Internal Transfer',
        denom,
      })
    }

    const hasTokenPair = tokenPairsMap[denom]?.enabled

    // Add transfer option between Carbon Core and Carbon EVM if token pair exists
    // If it is token group, only add for if it is the grouped token itself (when registeredGroupTokens is empty)
    if (hasTokenPair && (!isGroupToken || (isGroupToken && !registeredGroupTokens.length))) {
      const tokenTransferOption = generateTransferOptionData('Carbon EVM', TransferMethod.Bridge, TransferKey.CarbonEVMBridge)
      transferOptions.push({
        ...tokenTransferOption,
        denom,
      })
    }

    const transferChannelObj = transferOptions.reduce((prev: {
      [key: string]: OptionItem
    }, channel: TransferChannel) => {
      if (!channel.blockchain) return prev
      if (prev[channel.blockchain] && !prev[channel.blockchain].channels.find(currentChannel => currentChannel.key === channel.key)) {
        prev[channel.blockchain].channels.push(channel)
      } else {
        const tokenStandard = tokenStandards[channel.blockchain]
        prev[channel.blockchain] = {
          title: tokenStandard ? `${channel.blockchain} (${tokenStandard})` : channel.blockchain,
          blockchain: channel.blockchain,
          channels: [channel],
        }
      }
      return prev
    }, {})

    const currentDenom = isGroupToken ? registeredGroupTokens.find((token) => token.symbol.toLowerCase() === groupTokenOption.toLowerCase())?.denom ?? '' : denom
    const currentTokenBlockchain = GroupOptionDefaultChain[groupTokenOption] ?? sdk.token.getBlockchainV2(currentDenom) ?? ''
    const currentBlockchainChannel = Object.values(transferChannelObj).find((channel) => channel.blockchain.toLowerCase() === currentTokenBlockchain.toLowerCase())

    const sortedTransferChannels = Object.values(transferChannelObj)
      .sort((aChannel: OptionItem, bChannel: OptionItem) => {
        const aBlockchain = aChannel.blockchain.toLowerCase() === 'native' ? 'Carbon' : aChannel.blockchain
        const bBlockchain = bChannel.blockchain.toLowerCase() === 'native' ? 'Carbon' : bChannel.blockchain
        const blockchainRankA = demexConfig.transferOptions[aBlockchain] ?? 10000
        const blockchainRankB = demexConfig.transferOptions[bBlockchain] ?? 10000
        return blockchainRankA - blockchainRankB
      })

    if (currentBlockchainChannel) {
      const filtered = sortedTransferChannels.filter((channel) => channel.blockchain.toLowerCase() !== currentTokenBlockchain?.toLowerCase())
      return [currentBlockchainChannel, ...filtered]
    }

    return sortedTransferChannels
  }, [denom, sdk, denomTraces, wrappedTokens, additionalIbcTokens, isGroupToken, registeredGroupTokens, tokenPairsMap, groupTokenOption, transferType, isConnectedRainBowkit, connector?.name, demexConfig.transferOptions, bridgeConnections, externalToken, loading, selectedToken])

  return transferOptionsArr
}

export default useTransferOptions
