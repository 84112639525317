import { Theme, alpha, makeStyles } from '@material-ui/core'

import { LootBoxType, TierType } from 'js/constants/demexPoints'

export const useCommonStyles = makeStyles((theme) => ({
  posRelative: {
    position: 'relative',
  },
  posAbsolute: {
    position: 'absolute',
  },
  posFixed: {
    position: 'fixed',
  },
  mt05: {
    marginTop: theme.spacing(0.5),
  },
  mt0: {
    marginTop: theme.spacing(0),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  mb0: {
    marginBottom: theme.spacing(0),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb15: {
    marginBottom: theme.spacing(1.5),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mr0: {
    marginRight: theme.spacing(0),
  },
  mr05: {
    marginRight: theme.spacing(0.5),
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  mr3: {
    marginRight: theme.spacing(3),
  },
  mr4: {
    marginRight: theme.spacing(4),
  },
  ml0: {
    marginLeft: theme.spacing(0),
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  ml3: {
    marginLeft: theme.spacing(3),
  },
  ml4: {
    marginLeft: theme.spacing(4),
  },
  pt0: {
    paddingTop: theme.spacing(0),
  },
  pt1: {
    paddingTop: theme.spacing(1),
  },
  pt2: {
    paddingTop: theme.spacing(2),
  },
  pt3: {
    paddingTop: theme.spacing(3),
  },
  pt4: {
    paddingTop: theme.spacing(4),
  },
  pb0: {
    paddingBottom: theme.spacing(0),
  },
  pb1: {
    paddingBottom: theme.spacing(1),
  },
  pb2: {
    paddingBottom: theme.spacing(2),
  },
  pb3: {
    paddingBottom: theme.spacing(3),
  },
  pb4: {
    paddingBottom: theme.spacing(4),
  },
  pr0: {
    paddingRight: theme.spacing(0),
  },
  pr1: {
    paddingRight: theme.spacing(1),
  },
  pr2: {
    paddingRight: theme.spacing(2),
  },
  pr3: {
    paddingRight: theme.spacing(3),
  },
  pr4: {
    paddingRight: theme.spacing(4),
  },
  pl0: {
    paddingLeft: theme.spacing(0),
  },
  pl1: {
    paddingLeft: theme.spacing(1),
  },
  pl2: {
    paddingLeft: theme.spacing(2),
  },
  pl3: {
    paddingLeft: theme.spacing(3),
  },
  px2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  pl4: {
    paddingLeft: theme.spacing(4),
  },
  justifyContentStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  justifyContentCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  justifyContentEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  justifyContentSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  justifyContentSpaceAround: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  justifyContentSpaceEvenly: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  justifyContentInitial: {
    display: 'flex',
    justifyContent: 'initial',
  },
  justifyContentStretch: {
    display: 'flex',
    justifyContent: 'stretch',
  },
  alignItemsStart: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  alignItemsEnd: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  alignItemsBaseline: {
    display: 'flex',
    alignItems: 'baseline',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flex08: {
    flex: 0.8,
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flex3: {
    flex: 3,
  },
  flex4: {
    flex: 4,
  },
  flex5: {
    flex: 5,
  },
  flex7: {
    flex: 7,
  },
  flex8: {
    flex: 8,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  backgroundTransparent: {
    background: 'transparent',
  },
  gainColor: {
    color: theme.palette.buy,
  },
  lossColor: {
    color: theme.palette.sell,
  },

  textNoWrap: {
    whiteSpace: 'nowrap',
  },
  notionalTextColor: {
    color: theme.palette.text.secondary,
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
}))

export const StyleUtils = {
  buttonGradient: (theme: Theme) => `linear-gradient(270deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
  buttonGradientHover: (theme: Theme) => `linear-gradient(270deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  tradingLeagueGradient: 'linear-gradient(90deg, #63D0F3 0%, #946DEE 32.81%, #F5827E 68.23%, #FECE5D 100%)',
  switchGradient: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(270deg, #482BFF 0%, #007AFF 100%)',
  primaryBranding: 'linear-gradient(270deg, #482BFF 0%, #007AFF 100%)',
  primaryShadow: '0 0 25px 3px #482BFF40, 0 4px 6px -4px #007AFF80',
  primaryHover: 'linear-gradient(270deg, #007AFF 0%, #482BFF 100%)',
  primaryTextGradient: 'linear-gradient(270deg, #007AFF 0%, #00B2FF 100%)', // also accent Blue | Info
  pieChartActiveShapeText: '#F5F5F7',
  primaryLayerGradient: 'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(270deg, #482BFF 0%, #007AFF 100%)',
  accentSuccess: 'linear-gradient(270deg, #00A862 0%, #00AF92 100%)',
  accentSuccessHover: 'linear-gradient(270deg, #00AF92 0%, #00A862 100%)',
  accentError: 'linear-gradient(270deg, #E84747 0%, #FF5107 100%)',
  accentErrorHover: 'linear-gradient(270deg, #FF5107 0%, #E84747 100%)',
  accentWarning: 'linear-gradient(90deg, #FFA800 0%, #FF5107 100%)',
  accentWarningHover: 'linear-gradient(90deg, #FF5107 0%, #FFA800 100%)',
  accentPrelaunch: 'linear-gradient(90deg, #EB1B72 0%, #990C1D 100%)',
  successFilter: 'rgba(0, 168, 98, 0.05)',
  boxShadow: '0px 0px 48px rgba(0, 0, 0, 0.64)',
  borderBlue: '1px solid rgba(0, 122, 255, 1)',
  healthFactorGradient: '-webkit-linear-gradient(left, rgb(233,72,70) 0%, rgb(255,83,8) 20%, rgb(255,168,1) 50%, rgb(1,169,102) 80%, rgb(1,176,145) 100%)',
  widgetGradient: 'linear-gradient(99.85deg, #4A4A4A -85.1%, #131313 113.29%)',
  dashedCircleBackground: // dashed circle background image css generated from: https://kovart.github.io/dashed-border-generator/
    "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23A4A5A8' stroke-width='2' stroke-dasharray='9%25%2c 12%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")",
  commonDividerColor: '#FFFFFF14',
  bannerGradient: (theme: Theme) => {
    if (theme.palette?.type === 'light') return '#FFFFFF'
    return 'linear-gradient(298.71deg, #282B2D 32.3%, #000000 100%)'
  },
  bannerFadeBottom: (theme: Theme) => {
    if (theme.palette?.type === 'light') return 'radial-gradient(103.16% 144.37% at 50% -18.77%, #F3F3F3 0%, #FFFFFF 73.91%)'
    return 'radial-gradient(152.61% 212.74% at 48.6% -27.63%, #272A2E 31.06%, #1A1D1F 73.91%)'
  },
  dropShadow: (theme: Theme) => `0px 0px 16px ${theme.palette.shadow}`,
  fadeBottom: (theme: Theme) => {
    if (theme.palette?.type === 'light') return 'linear-gradient(0deg, rgba(26, 29, 31, 0.05) 1.48%, rgba(26, 29, 31, 0) 30%)'
    return 'linear-gradient(0deg, rgba(0, 0, 0, 0.225) 1.48%, rgba(0, 0, 0, 0) 30%)'
  },
  fadeTopBottom: (theme: Theme) => {
    if (theme.palette?.type === 'light') return 'linear-gradient(0deg, rgba(26, 29, 31, 0.05) 1.48%, rgba(26, 29, 31, 0) 25%, rgba(26, 29, 31, 0) 75%, rgba(26, 29, 31, 0.05) 99.98%)'
    return 'linear-gradient(0deg, rgba(0, 0, 0, 0.225) 1.48%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.225) 99.98%)'
  },
  fadeTop: (theme: Theme) => {
    if (theme.palette?.type === 'light') return 'linear-gradient(180deg, rgba(26, 29, 31, 0.05) 1.48%, rgba(26, 29, 31, 0) 30%)'
    return 'linear-gradient(180deg, rgba(0, 0, 0, 0.225) 1.48%, rgba(0, 0, 0, 0) 30%)'
  },
  scrollBar: (theme: Theme) => ({ // to add to box scrolling box
    '&::-webkit-scrollbar': {
      // the actual width is this value minus twice of border width
      width: theme.spacing(0.75),
      height: theme.spacing(0.75),
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.scrollbar.track,
      borderRadius: '10px',
      margin: theme.spacing(0.25),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: theme.palette.scrollbar.thumb,
      // the actual border radius should be this value minus twice of border width
      borderRadius: theme.spacing(2.5),
    },
    '& *': {
      scrollbarColor: 'transparent',
    },
  }),
  hideScrollBar: () => ({
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' as any,  /* Internet Explorer 10+ */
    scrollbarWidth: 'none' as any, /* Firefox */
  }),
  gradientStyles: () => ({
    background: 'linear-gradient(270deg, #007AFF 0%, #00B2FF 100%)',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  }),
  dialogBackground: 'linear-gradient(180deg, #FFFFFF00 80%, #1A1D1F 100%)',
  blurOverride: () => ({
    backdropFilter: 'none',
  }),
  dropdownAnimation: (liteMode?: boolean) => ({
    transition: 'all 0.2s ease-in-out',
    opacity: 1,
    maxHeight: liteMode ? '400px' : '100vh',
  }),
  hoverAnimationDuration: () => ({
    transition: 'all 0.2s ease-in-out',
  }),
  textButtonHoverAnimationDuration: () => ({
    transition: 'all 0.3s ease-out',
  }),
  hoverAnimation: (theme: Theme) => ({
    '&:hover': {
      '& path': {
        fill: `${theme.palette.text.animated} !important`,
        transition: `${StyleUtils.hoverAnimationDuration()} !important`,
      },
    },
  }),

  lineClamp: (noOfLines: number) => ({
    display: '-webkit-box',
    WebkitLineClamp: noOfLines,
    WebkitBoxOrient: 'vertical' as any,
    overflow: 'hidden',
  }),

  primaryButtonStyle: (theme: Theme) => ({
    background: 'linear-gradient(270deg, var(--primaryDark), var(--primaryMain))',
    transition: '--primaryDark .7s, --primaryMain .7s',
    '&:hover': {
      '--primaryDark': theme.palette.primary.main,
      '--primaryMain': theme.palette.primary.dark,
    },
    '@supports not (background: paint(something))': { // if no CSS Houdini support
      background: StyleUtils.primaryBranding,
      transition: 'background .3s',
      '&:hover': {
        background: StyleUtils.primaryHover,
      },
    },
  }),
  successButtonStyle: (theme: Theme) => ({
    background: 'linear-gradient(270deg, var(--successMain), var(--successLight))',
    transition: '--successMain .7s, --successLight .7s',
    '&:hover': {
      '--successMain': theme.palette.success.light,
      '--successLight': theme.palette.success.main,
    },
    '@supports not (background: paint(something))': { // if no CSS Houdini support
      background: StyleUtils.accentSuccess,
      transition: 'background .3s',
      '&:hover': {
        background: StyleUtils.accentSuccessHover,
      },
    },
    '&.Mui-disabled': {
      background: `${theme.palette.action.disabledBackground} !important`,
      color: theme.palette.text.tertiary ?? 'inherit',
    },
  }),
  errorButtonStyle: (theme: Theme) => ({
    background: 'linear-gradient(270deg, var(--errorMain), var(--errorLight))',
    transition: '--errorMain .7s, --errorLight .7s',
    '&:hover': {
      '--errorMain': theme.palette.error.light,
      '--errorLight': theme.palette.error.main,
    },
    '@supports not (background: paint(something))': { // if no CSS Houdini support
      background: StyleUtils.accentError,
      transition: 'background .3s',
      '&:hover': {
        background: StyleUtils.accentErrorHover,
      },
    },
    '&.Mui-disabled': {
      background: `${theme.palette.action.disabledBackground} !important`,
      color: theme.palette.text.tertiary ?? 'inherit',
    },
  }),
  warningButtonStyle: (theme: Theme) => ({
    background: 'linear-gradient(90deg, var(--warningMain), var(--warningDark))',
    transition: '--warningMain .7s, --warningDark .7s !important',
    '&:hover': {
      '--warningMain': theme.palette.warning.dark,
      '--warningDark': theme.palette.warning.main,
    },
    '@supports not (background: paint(something))': { // if no CSS Houdini support
      background: StyleUtils.accentWarning,
      transition: 'background .3s',
      '&:hover': {
        background: StyleUtils.accentWarningHover,
      },
    },
    '&.Mui-disabled': {
      background: `${theme.palette.action.disabledBackground} !important`,
      color: theme.palette.text.tertiary ?? 'inherit',
    },
  }),
  borderRadius: 4,
  borderStyle: (color: string, pixels = 1) => `${pixels}px solid ${color}`,
  tradingLeagueRowColor: (theme: Theme): string => {
    return `radial-gradient(133.93% 133.93% at 50% -33.93%, ${theme.palette.background.secondary} 0%, ${alpha(theme.palette.background.secondary, 0)} 100%)`
  },
  competitionColors: {
    light: '#4A7DFF',
    dark: '#2738C6'
  },
  infoBannerBackground: (theme: Theme) => ({
    background: theme.palette.type === 'dark' ? '#13222F' : '#DFF0FF'
  }),
  warningBannerBackground: (theme: Theme) => ({
    background: theme.palette.type === 'dark' ? '#241E11' : '#FFF8DF'
  }),
  errorBannerBackground: (theme: Theme) => ({
    background: theme.palette.type === 'dark' ? '#231112' : '#FFDFDF'
  }),
  successBannerBackground: (theme: Theme) => ({
    background: theme.palette.type === 'dark' ? '#042C1D' : '#DFFFF0'
  }),
  demexPoints: {
    blue: '#9EBFFF',
    translucentBlue: 'rgba(158, 191, 255, 0.5)',
    borderBackground: 'linear-gradient(180deg, rgba(158, 191, 255, 0.5) 0%, rgba(56, 76, 134, 0.5) 76.8%)',
    translucentBackground: 'rgba(26, 29, 31, 0.8)',
  },
  demexPointsTiers: (): { [key in TierType]: any } => ({
    [TierType.Bronze]: {
      background: 'linear-gradient(135deg, #CC9450 -3.92%, #724C33 103.92%)',
      boxShadow: '0px 0px 60px -12px #36221559'
    },
    [TierType.Silver]: {
      background: 'linear-gradient(134.83deg, #ECECEC -34.55%, #969696 100%)',
      boxShadow: '0px 0px 60px -12px #96969626'
    },
    [TierType.Gold]: {
      background: 'linear-gradient(90deg, #B49328 0%, #EFC034 100%, #957514 100%)',
      boxShadow: '0px 0px 80px -12px #EFC03426'
    },
    [TierType.Diamond]: {
      background: 'linear-gradient(135deg, #14CCFF -3.92%, #0C71E9 103.92%)',
      boxShadow: '0px 0px 60px -12px #14CCFF59'
    },
  }),
  demexPointsLootBoxes: (theme: Theme): { [key in LootBoxType]: any } => ({
    [LootBoxType.Retro]: {},
    [LootBoxType.Common]: {
      color: theme.palette.text.primary,
      background: theme.palette.type === 'dark' ?
        'linear-gradient(180.26deg, #3D3D3D 0.24%, rgba(39, 46, 43, 0.75) 99.77%)'
        : 'linear-gradient(180.26deg, #CDC2C2 0.24%, rgba(251, 250, 249, 0.75) 99.77%)',
      borderColor: theme.palette.divider,
      dividerColor: '#B1B1B1'
    },
    [LootBoxType.Uncommon]: {
      color: theme.palette.type === 'dark' ? '#82EBA5' : '#23452C',
      background: theme.palette.type === 'dark' ?
        'linear-gradient(180.26deg, #23452C 0.23%, rgba(39, 46, 43, 0.75) 99.77%)'
        : 'linear-gradient(180.26deg, #82EBA5 0.23%, rgba(251, 250, 249, 0.75) 99.77%)',
      borderColor: 'rgba(0, 203, 36, 0.08)',
      dividerColor: '#00CB24'
    },
    [LootBoxType.Rare]: {
      color: theme.palette.type === 'dark' ? '#57BCFC' : '#21366C',
      background: theme.palette.type === 'dark' ?
        'linear-gradient(180.26deg, #21366C 0.23%, rgba(39, 41, 46, 0.75) 99.77%)'
        : 'linear-gradient(180.26deg, #57BCFC 0.23%, rgba(251, 250, 249, 0.75) 99.77%)',
      borderColor: 'rgba(60, 136, 250, 0.08)',
      dividerColor: '#3C88FA'
    },
    [LootBoxType.Epic]: {
      color: theme.palette.type === 'dark' ? '#CA7AF7' : '#42216C',
      background: theme.palette.type === 'dark' ?
        'linear-gradient(180.26deg, #42216C 0.23%, rgba(43, 39, 46, 0.75) 99.77%)'
        : 'linear-gradient(180.26deg, #CA7AF7 0.23%, rgba(251, 250, 249, 0.75) 99.77%)',
      borderColor: 'rgba(166, 60, 250, 0.08)',
      dividerColor: '#A63CFA'
    },
    [LootBoxType.Legendary]: {
      color: theme.palette.type === 'dark' ? '#FFB25E' : '#6C3321',
      background: theme.palette.type === 'dark' ?
        'linear-gradient(180.26deg, #6C3321 0.23%, rgba(46, 42, 39, 0.75) 99.77%)'
        : 'linear-gradient(180.26deg, #FFB25E 0.23%, rgba(251, 250, 249, 0.75) 99.77%)',
      borderColor: 'rgba(250, 129, 60, 0.08)',
      dividerColor: '#FA813C'
    }
  }),
  rechartTooltipContainer: (theme: Theme) => ({
    ...theme.typography.body3,
    backgroundColor: theme.palette.background.primary,
    opacity: 0.95,
    padding: theme.spacing(1),
    borderRadius: '4px',
    border: `1px solid ${theme.palette.background.divider}`,
    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.64)',
    minWidth: '12rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  }),
  rechartLabelContainer: (theme: Theme) => ({
    display: 'flex',
    columnGap: `${theme.spacing(0.5)}px`,
    alignItems: 'center',
  }),
}
