import { Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import BuildTimestamp from 'js/components/Common/BuildTimestamp'
import Paths from 'js/constants/paths'
import { EvtCategory, useEventTrackers } from 'js/hooks'
import useDebugInfo from 'js/hooks/useDebugInfo'
import { useTradeMode } from 'js/hooks/useTradeMode'
import { isWalletConnected } from 'js/state/modules/account/selectors'
import { getAppVersion } from 'js/state/modules/app/selectors'
import { StyleUtils } from 'js/utils'

import { ReactComponent as HelpAndSupportIcon } from 'assets/QuestionCircle.svg'

import BottomButton from './BottomButton'
import HelpAndSupport from './HelpAndSupport'
import Toolbar from './Toolbar'
import { DisplaySettings, NodeSelectDropdown, SWTHFeeDropdown } from './dropdowns'

import StandardDialog from '../Common/StandardDialog'
import V2Switch from '../Common/V2Switch'

const BlockHeight = React.lazy(() => import('js/components/Common/BlockHeight'))

interface Props { }

const BottomAppBar: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const eventTrackers = useEventTrackers()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const tradeMode = useTradeMode()
  const renderDebugInfo = useDebugInfo()

  const isLoggedIn = useSelector(isWalletConnected)
  const appVersion = useSelector(getAppVersion)

  const isLiteMode = tradeMode.isSimpleMode
  const displayPaths = [Paths.Trade.Main, Paths.Lite]
  const isRecentTrades = useRouteMatch(Paths.Trade.RecentTrades)
  const enableDisplaySettings = useRouteMatch(displayPaths) && !isRecentTrades

  const [openModal, setOpenModal] = React.useState(false)

  const handleTradeModeChange = () => {
    tradeMode.prompt()
  }


  return (
    <Toolbar className={classes.toolBar} toolbarClass={classes.toolClass}>
      <div className={classes.utilButtons}>
        <NodeSelectDropdown />
        {isDesktop && isLoggedIn && (
          <React.Fragment>
            <div className={classes.divider} />
            <SWTHFeeDropdown />
          </React.Fragment>
        )}
        {enableDisplaySettings && (
          <Hidden mdDown>
            <div className={classes.divider} />
            <div className={classes.tradeModeSwitch}>
              <span className={clsx(classes.switchLabel, { active: isLiteMode })}>Lite</span>
              <V2Switch
                className={classes.switch}
                checked={!isLiteMode}
                onChange={handleTradeModeChange}
              />
              <span className={clsx(classes.switchLabel, { active: !isLiteMode })}>Pro</span>
            </div>
            <div className={classes.divider} />
            {!isLiteMode && <DisplaySettings />}
          </Hidden>
        )}
      </div>
      <div className={classes.brandSection}>
        <Hidden smDown>
          <Suspense>
            <BlockHeight />
          </Suspense>
          {appVersion && <span>v{appVersion}</span>}
          {renderDebugInfo && <BuildTimestamp />}
        </Hidden>
        <Hidden smDown>
          <BottomButton
            onClick={() => {
              eventTrackers.sendEvent(EvtCategory.Navigation, 'menu_support', {source: 'bottomBar'})
              setOpenModal(true)
            }}
            icon={HelpAndSupportIcon}
            label='Help & Feedback'
            customClassName={classes.supportText}
            customIconClassName={classes.supportIcon}
          />
          <StandardDialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            headerTitle={(
              <div className={classes.dialogTitleContainer}>
                <div className={classes.dialogTitle}>
                  Help & Feedback
                </div>
              </div>
            )}
            isCustomHeader
            customClasses={{
              paper: classes.dialogPaper,
              content: classes.dialogContent,
              closeButton: classes.dialogCloseButton,
            }}
            noSubmitButton
            noCancelButton
          >
            <HelpAndSupport />
          </StandardDialog>
        </Hidden>
      </div>
    </Toolbar>
  )
}

const useStyles = makeStyles((theme) => ({
  utilButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  brandSection: {
    ...theme.typography.body4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0, 1.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  toolBar: {
    ...theme.typography.body3,
    backgroundColor: theme.palette.background.primary,
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 0,
    zIndex: 115,
    [theme.breakpoints.only('sm')]: {
      position: 'unset',
      zIndex: 'auto',
      flexDirection: 'unset',
      padding: theme.spacing(0.5, 1.5),
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.only('xs')]: {
      position: 'unset',
      zIndex: 'auto',
      display: 'block',
      padding: theme.spacing(0.5, 1.5),
      maxHeight: '96px',
      marginBottom: theme.spacing(7),
    },
    '&.sidebar': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  toolClass: {
    minHeight: theme.spacing(3.5),
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  divider: {
    margin: '0 12px',
    backgroundColor: StyleUtils.commonDividerColor,
    width: '1px',
    height: '16px',
  },
  customDarkIcon: {
    '& > svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),
    }
  },
  supportText: {
    color: theme.palette.text.primary,
  },
  supportIcon: {
    marginRight: theme.spacing(0.25),
    paddingTop: theme.spacing(0.25),
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    justifyContent: 'center',
    '& svg': {
      '& path': {
        stroke: theme.palette.text.primary,
        strokeWidth: 1.5,
        fill: 'unset',
      }
    },
    '& > svg': {
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      width: theme.spacing(2),
      height: theme.spacing(2),
      '& > svg': {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
      },
    },
  },
  tradeModeSwitch: {
    ...theme.typography.body4,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0, 0.75),
  },
  switch: {
    margin: 0,
  },
  switchLabel: {
    '&.active': {
      color: '#fff',
    }
  },
  dialogPaper: {
    maxWidth: '450px',
  },
  dialogTitleContainer: {
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogTitle: {
    width: '100%',
    padding: theme.spacing(1, 4),
    ...theme.typography.h4,
  },
  dialogCloseButton: {
    top: '4px'
  },
}))

export default BottomAppBar
