import { WSConnectorTypes } from "carbon-js-sdk"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { LoadingTask } from "js/models"
import { getThirdPartyAPIStatuses, getCarbonSDK } from "js/state/modules/app/selectors"
import { CDPQueryTasks } from "js/state/modules/cdp/types"
import { getMarket } from "js/state/modules/exchange/selectors"
import { runFetchHistoricalFundingGraphData } from "js/state/modules/exchange/types"
import { PoolsTaskNames } from "js/state/modules/liquidityPool/types"
import { getLoadingTasks, getWsInit } from "js/state/modules/loadingTask/selectors"
import { StakeTasks } from "js/state/modules/stake/types"
import { VaultTasks } from "js/state/modules/vaults/types"

export enum LoadingItems {
  balances = 'balances',
  cdpModuleBalances = 'cdpModuleBalances',
  pools = 'pools',
  borrows = 'borrows',
  collaterals = 'collaterals',
  tokenSupply = 'tokenSupply',
  tokenDebts = 'tokenDebts',
  emodeCategories = 'emodeCategories',
  positions = 'positions',
  tokenPrices = 'tokenPrices',
  cdpAssets = 'cdpAssets',
  liquidations = 'liquidations',
  moduleTotal = 'moduleTotal',
  delegations = 'delegations',
  equivalences = 'equivalences',
  rewardsScheme = 'rewardsScheme',
  orders = 'orders',
  ordersByMarket = 'ordersByMarket',
  trades = 'trades',
  tradesByMarket = 'tradesByMarket',
  positionsByMarket = 'positionsByMarket',
  perpPools = 'perpPools',
  perpPoolPerformance = 'perpPoolPerformance',
  chaininfoMap = 'chaininfoMap',
  denomTraces = 'denomTraces',
  tokens = 'tokens',
  insightsDown = 'insightsDown',
  recentTrades = 'recentTrades',
  marketStats = 'marketStats',
  poolRewards = 'poolRewards',
  cdpRewardDebts = 'cdpRewardDebts',
  cdpRewardSchemes = 'cdpRewardSchemes',
  delegationRewards = 'delegationRewards',
  stakeEquivalenceCalcs = 'stakeEquivalenceCalcs',
  distributedRewards = 'distributedRewards',
  plpIncentivesRewardDistributor = 'plpIncentivesRewardDistributor',
}

type LoadingItem = {
  [index: string]: boolean | LoadingTask
}

const useLoading = (...tasks: LoadingItems[]) => {
  const wsInit = useSelector(getWsInit)
  const loadingTasks = useSelector(getLoadingTasks)
  const thirdPartyAPIStatuses = useSelector(getThirdPartyAPIStatuses)
  const insightsDown = thirdPartyAPIStatuses.isCarbonInsightsDown
  const sdk = useSelector(getCarbonSDK)
  const market = useSelector(getMarket)

  const moduleAddress = sdk?.cdp.getCdpModuleAddress()

  const address = sdk?.wallet?.bech32Address

  const loadingItems: LoadingItem = useMemo(() => {
    return {
      'balances': !wsInit.has(`${WSConnectorTypes.WSChannel.balances}${address ? `:${address}` : ''}`),
      'cdpModuleBalances': !wsInit.has(`${WSConnectorTypes.WSChannel.balances}${moduleAddress ? `:${moduleAddress}` : ''}`),
      'pools': !wsInit.has(WSConnectorTypes.WSChannel.pools),
      'borrows': !wsInit.has(`${WSConnectorTypes.WSChannel.cdp_borrows}${address ? `:${address}` : ''}`),
      'collaterals': !wsInit.has(`${WSConnectorTypes.WSChannel.cdp_collaterals}${address ? `:${address}` : ''}`),
      'tokenSupply': !wsInit.has(WSConnectorTypes.WSChannel.cdp_token_supply),
      'tokenDebts': !wsInit.has(WSConnectorTypes.WSChannel.cdp_token_debts),
      'emodeCategories': loadingTasks[CDPQueryTasks.CDPEModeCategories],
      'positions': !wsInit.has(`${WSConnectorTypes.WSChannel.positions}${address ? `:${address}` : ''}`),
      'tokenPrices': !wsInit.has(WSConnectorTypes.WSChannel.token_prices),
      'cdpAssets': loadingTasks[CDPQueryTasks.CDPAssets],
      'moduleTotal': loadingTasks['queryModuleTotal'],
      'liquidations': loadingTasks['runQueryLiquidationsHistory'],
      'delegations': loadingTasks['queryDelegations'],
      'perpPools': loadingTasks[VaultTasks.QueryPerpPools],
      'perpPoolPerformance': loadingTasks['runQueryPerpPoolPerformance'],
      'denomTraces': loadingTasks['runQueryDenomTraces'],
      'chaininfoMap': loadingTasks['runQueryChaininfoMap'],
      'tokens': loadingTasks['runTokensFetch'],
      'equivalences': loadingTasks[StakeTasks.Equivalence],
      'rewardsScheme': !wsInit.has(WSConnectorTypes.WSChannel.cdp_reward_schemes),
      'positionsByMarket': !wsInit.has(`${WSConnectorTypes.WSChannel.positions_by_market}${market?.id ? `:${market.id}` : ''}${address ? `:${address}` : ''}`),
      'orders': !wsInit.has(`${WSConnectorTypes.WSChannel.orders}${address ? `:${address}` : ''}`),
      'ordersByMarket': !wsInit.has(`${WSConnectorTypes.WSChannel.orders_by_market}${market?.id ? `:${market.id}` : ''}${address ? `:${address}` : ''}`),
      'trades': !wsInit.has(`${WSConnectorTypes.WSChannel.account_trades}${address ? `:${address}` : ''}`),
      'tradesByMarket': !wsInit.has(`${WSConnectorTypes.WSChannel.account_trades_by_market}${market?.id ? `:${market.id}` : ''}${address ? `:${address}` : ''}`),
      'recentTrades': !wsInit.has(`${WSConnectorTypes.WSChannel.recent_trades}${market?.id ? `:${market.id}` : ''}`),
      'insightsDown': insightsDown,
      'marketStats': !wsInit.has(WSConnectorTypes.WSChannel.market_stats),
      'poolRewards': loadingTasks[PoolsTaskNames.UnclaimedRewards],
      'cdpRewardDebts': !wsInit.has(`${WSConnectorTypes.WSChannel.cdp_reward_debts}${address ? `:${address}` : ''}`),
      'cdpRewardSchemes': !wsInit.has(WSConnectorTypes.WSChannel.cdp_reward_schemes),
      'delegationRewards': loadingTasks[StakeTasks.DelegationRewards],
      'stakeEquivalenceCalcs': loadingTasks[StakeTasks.StakeEquivalenceCalcs],
      'distributedRewards': loadingTasks[PoolsTaskNames.DistributedRewards],
      'historicalFundingGraphData': loadingTasks[runFetchHistoricalFundingGraphData],
      'plpIncentivesRewardDistributor': loadingTasks[VaultTasks.QueryPLPRewardsDistributor]
    }
  }, [wsInit, address, moduleAddress, loadingTasks, market?.id, insightsDown])

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const isLoading = tasks.some(task => loadingItems[task])

    setLoading((prevLoading) => {
      if (prevLoading !== isLoading) {
        return isLoading
      }
      return prevLoading
    })
  }, [loadingItems, tasks])

  return loading
}

export default useLoading
