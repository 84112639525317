import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CarbonSDK } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { BN_ONE, bnOrZero } from 'carbon-js-sdk/lib/util/number'
import React, { Fragment, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'

import { TransferChannel } from 'js/constants/TransferOptions'
import { carbonStablecoinDenom, usdGroupedToken } from 'js/constants/assets'
import Paths from 'js/constants/paths'
import { useUsdValue } from 'js/hooks'
import useLoading, { LoadingItems } from 'js/hooks/useLoading'
import { isWalletConnected } from 'js/state/modules/account/selectors'
import { setFeeToggleOpen } from 'js/state/modules/app/actions'
import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { RootState } from 'js/state/modules/rootReducer'
import { checkSelectToken } from 'js/state/modules/walletBalance/actions'
import { toShorterNum } from 'js/utils/number'
import { formatSmallNumber } from 'js/utils/strings'

import AmountField from '../AmountField'
import { AmountFieldProps } from '../AmountField/AmountField'
import LoadingSkeleton from '../LoadingSkeleton/LoadingSkeleton'
import SubscriptText from '../SubscriptText'
import TokenSymbol from '../TokenSymbol'
import TypographyLabel from '../TypographyLabel'

type Props = AmountFieldProps & {
  tokenBalance?: BigNumber
  assetDenom?: string
  assetLabel?: string
  showIcon?: boolean
  depositMoreDenom?: string
  primaryLabel?: React.ReactNode
  secondaryLabel?: React.ReactNode
  hideSecondaryLabel?: boolean
  wrapSecondaryLabel?: boolean
  hideUsdValue?: boolean
  limit?: React.ReactNode
  actionBtnLabel?: string
  actionBtnLabelClassName?: string;
  actionBtnClass?: string;
  noDivider?: boolean;
  disableRipple?: boolean
  onClickActionBtn?: () => void
  balanceLoadingProp?: boolean
  totalOrderUSD?: BigNumber
}

const AmountInput: React.FC<Props> = (props: Props) => {
  const { tokenBalance, assetDenom, assetLabel, showIcon = false,
    depositMoreDenom, primaryLabel, secondaryLabel,
    hideSecondaryLabel = false, wrapSecondaryLabel = false, hideUsdValue = false, balanceLoadingProp,
    actionBtnLabel = 'Max', actionBtnLabelClassName, actionBtnClass, noDivider, disableRipple, onClickActionBtn,
    limit, value, totalOrderUSD, ...inputProps } = props
  const isLoggedIn = useSelector(isWalletConnected)
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const history = useHistory()
  const dispatch = useDispatch()
  const sdk = useSelector(getCarbonSDK)
  const isDepositPage = useRouteMatch(Paths.Account.Deposit)
  const isWithdrawPage = useRouteMatch(Paths.Account.Withdraw)
  const selectedToken = useSelector<RootState, Token | null>((store) => store.walletBalance.selectedToken)
  const depositOption = useSelector<RootState, TransferChannel | null>((store) => store.walletBalance.depositOption)

  const tokens = useSelector<RootState, Token[]>((state) => state.app.tokens)
  const isBalanceLoading = useLoading(LoadingItems.balances, LoadingItems.collaterals, LoadingItems.tokenDebts, LoadingItems.tokenSupply, LoadingItems.tokenPrices, LoadingItems.emodeCategories)


  const { isDepositMore } = React.useMemo(() => {
    const balanceBN = bnOrZero(tokenBalance)

    if (!depositMoreDenom || !sdk) {
      return {
        isDepositMore: false
      }
    }

    const isCdpToken = CarbonSDK.TokenClient.isCdpToken(depositMoreDenom) // check if cdp token
    const isPoolToken = CarbonSDK.TokenClient.isPoolToken(depositMoreDenom) // check if pool token
    const isNativeStableCoin = sdk.token.isNativeStablecoin(depositMoreDenom) // check if USC
    return {
      isDepositMore: !isCdpToken && !isPoolToken && !isNativeStableCoin && balanceBN.eq(0)
    }
  }, [depositMoreDenom, sdk, tokenBalance])

  const { amountLabel, tokenDenom, balanceLabel } = React.useMemo(() => {
    if (!tokenBalance) {
      return {
        amountLabel: primaryLabel,
        tokenDenom: assetDenom,
        balanceLabel: (
          <div className={classes.labelItems}>
            {secondaryLabel}
          </div>
        )
      }
    }
    let bnValue = bnOrZero(tokenBalance)
    let formatted = bnValue.eq(0) ? bnValue.toFormat(2) : formatSmallNumber(bnValue, 0, 5, 5)
    if (isDepositPage) {
      return {
        amountLabel: primaryLabel ?? "Deposit Amount",
        tokenDenom: depositOption?.denom,
        balanceLabel: (
          <div className={classes.labelItems}>
            {secondaryLabel ?? "Balance"}&nbsp;:&nbsp;<SubscriptText formatted={formatted} />&nbsp;<TokenSymbol symbolOverride={assetLabel} denom={depositOption?.denom} />
          </div>
        )
      }
    }
    if (isWithdrawPage) {
      return {
        amountLabel: primaryLabel ?? "Withdraw Amount",
        tokenDenom: selectedToken?.denom,
        balanceLabel: (
          <div className={classes.labelItems}>
            {secondaryLabel ?? "Available Balance"}&nbsp;:&nbsp;<SubscriptText formatted={formatted} />&nbsp;<TokenSymbol denom={selectedToken?.denom} />
          </div>
        )
      }
    }
    const limitFormatted = (
      <Fragment>
        &nbsp;/&nbsp;
        {limit}
      </Fragment>
    )
    return {
      amountLabel: primaryLabel ?? "Amount",
      tokenDenom: assetDenom,
      balanceLabel: (
        <div className={classes.labelItems}>
          {secondaryLabel ?? "Available"}&nbsp;:&nbsp;<SubscriptText numSubscriptClass={classes.limitFormattedLabel} formatted={formatted} />{limit && limitFormatted}
        </div>
      )
    }
  }, [tokenBalance, isDepositPage, isWithdrawPage, limit, primaryLabel, assetDenom, classes.labelItems, secondaryLabel, depositOption?.denom, selectedToken?.denom, assetLabel, classes.limitFormattedLabel])

  const usdPrice = useUsdValue(tokenDenom ?? '')

  const handleSwthClick = useCallback((selectedToken: Token | null) => {
    return (() => {
      history.push(`${Paths.Account.Deposit}/${selectedToken?.denom}`)
      dispatch(setFeeToggleOpen())
    })
  }, [dispatch, history])

  const handleDirectToDeposit = useCallback(() => {
    if (!depositMoreDenom) return
    history.push(`${Paths.Account.Deposit}/${depositMoreDenom}`)
    const token = tokens.find((tkn) => tkn.denom === depositMoreDenom)
    if (token) {
      dispatch(checkSelectToken(token))
    }
  }, [depositMoreDenom, dispatch, history, tokens])

  const { fieldButtonTitle, onFieldButtonClick } = useMemo(() => {
    if ((isDepositPage || isWithdrawPage) && tokenBalance) {
      if (!tokenBalance.isZero()) {
        return { fieldButtonTitle: actionBtnLabel, onFieldButtonClick: onClickActionBtn }
      } else if (tokenBalance.isZero() && selectedToken?.denom === 'swth') {
        return { fieldButtonTitle: "Deposit", onFieldButtonClick: handleSwthClick(selectedToken) }
      }
      return { fieldButtonTitle: undefined, onFieldButtonClick: () => { } }
    }
    if (isDepositMore) {
      return { fieldButtonTitle: "Deposit", onFieldButtonClick: handleDirectToDeposit }
    }

    return { fieldButtonTitle: actionBtnLabel, onFieldButtonClick: onClickActionBtn }
  }, [isDepositPage, isWithdrawPage, tokenBalance, isDepositMore, actionBtnLabel, onClickActionBtn, selectedToken, handleSwthClick, handleDirectToDeposit])

  const formattedUsdValue = React.useMemo(() => {
    if (hideUsdValue) return undefined

    if(totalOrderUSD) return (<SubscriptText formatted={formatSmallNumber(totalOrderUSD, 0, 5, 5, true)} numSubscriptClass={classes.usdValueLabel} usdValue />)

    // disable notional value for usc/usdg
    if (tokenDenom === carbonStablecoinDenom || tokenDenom === usdGroupedToken || tokenDenom?.toLowerCase() === 'usd') return undefined

    const usdValue = usdPrice.times(bnOrZero(value))
    if (usdValue.gte(BN_ONE)) {
      return toShorterNum(usdValue, true, undefined, -2, 2)
    }
    return <SubscriptText formatted={formatSmallNumber(usdValue, 0, 5, 5, true)} numSubscriptClass={classes.usdValueLabel} usdValue />

  }, [classes.usdValueLabel, hideUsdValue, usdPrice, value, tokenDenom, totalOrderUSD])

  const hint = useMemo(() => {
    if (!isLoggedIn || hideSecondaryLabel) return null

    if (isMobile && wrapSecondaryLabel) return null

    if ((tokenBalance && isBalanceLoading) || balanceLoadingProp) return <LoadingSkeleton height={16} width={180} end={true} />

    return (
      <TypographyLabel className={classes.balanceLabel} color="textSecondary">
        {balanceLabel}
      </TypographyLabel>
    )
  }, [balanceLabel, isLoggedIn, isMobile, wrapSecondaryLabel, hideSecondaryLabel, tokenBalance, isBalanceLoading, balanceLoadingProp, classes.balanceLabel])


  return (
    <React.Fragment>
      <AmountField
        fullWidth
        variant="outlined"
        assetDenom={tokenDenom}
        assetLabel={assetLabel}
        showIcon={showIcon}
        className={classes.amtInput}
        label={amountLabel}
        subValue={formattedUsdValue}
        hint={hint}
        fieldButtonTitle={fieldButtonTitle}
        onFieldButtonClick={onFieldButtonClick}
        fieldButtonLabelClass={actionBtnLabelClassName}
        fieldButtonClass={actionBtnClass}
        noDivider={noDivider}
        disableRipple={disableRipple}
        value={value}
        {...inputProps}
      />

      {isMobile && wrapSecondaryLabel && (
        <TypographyLabel className={classes.balanceLabelMobile} color="textSecondary">
          {balanceLabel}
        </TypographyLabel>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  amtInput: {
    borderRadius: theme.spacing(0.5),
  },
  helperTxt: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.disabled,
  },
  usdValue: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.25),
  },
  label: {
    color: theme.palette.text.secondary,
  },
  gradientText: {
    ...theme.typography.body3,
    fontWeight: 700,
  },
  inputLabelBtn: {
    minWidth: theme.spacing(4),
    padding: theme.spacing(0, 1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      outline: 'none !important',
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
    },
  },
  balanceLabel: {
    textTransform: 'none',
  },
  balanceLabelMobile: {
    textTransform: 'none',
    margin: theme.spacing(0.75, 0, 2)
  },
  inputBtnLbl: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  labelItems: {
    ...theme.typography.body4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'flex-end',
    },
  },
  usdValueLabel: {
    ...theme.typography.body5,
    fontSize: '9.5px',
    width: '100%',
    color: theme.palette.text.secondary,
  },

  limitFormattedLabel: {
    ...theme.typography.body4,
  },
}))


export default AmountInput
