import { makeStyles, Theme } from '@material-ui/core'
import { BlockchainUtils } from 'carbon-js-sdk'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'

import { getCarbonSDK } from 'js/state/modules/app/selectors'
import { getTokenProperties } from 'js/state/modules/walletBalance/selectors'
import { isMultiChainDenom, SimpleMap, useCommonStyles } from 'js/utils'
import { isNeoBlockchain } from 'js/utils/externalTransfer'
import { chainLabel } from 'js/utils/strings'

import MultiChainGradient from 'assets/MultiChainGradient.png'

interface Props extends BlockchainObj {
  className?: string
  customTagClass?: string
  denom?: string
  denomB?: string
  noOfExtraBlockChains?: number
  noMargin?: boolean
  isTooltip?: boolean
  showOriginal?: boolean // for usd and cgt/1 (to show original blockchain)
}

interface BlockchainObj {
  blockchainA?: BlockchainUtils.BlockchainV2 | string
  blockchainB?: BlockchainUtils.BlockchainV2 | string
}

const ChainTag: React.FC<Props> = (props: Props) => {
  const { blockchainA: overrideBlockA, blockchainB: overrideBlockB, className, customTagClass, denom = '', denomB, noMargin, showOriginal = false } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const tokenProperties = useSelector(getTokenProperties)
  const sdk = useSelector(getCarbonSDK)

  const { blockchainA, blockchainB } = React.useMemo((): BlockchainObj => {
    const tokenProperty = tokenProperties[denom]
    let defaultBlockA = tokenProperty?.tokenBasicInfo.blockchain
    if (tokenProperty?.tokenCDPInfo.isCdpToken) {
      const underlyingDenom = tokenProperty?.tokenCDPInfo.underlyingDenom
      const underlyingProperty = tokenProperties[underlyingDenom]
      defaultBlockA = underlyingProperty?.tokenBasicInfo.blockchain
    }

    if (!defaultBlockA && (tokenProperty?.tokenBasicInfo.isPerpPoolToken || tokenProperty?.tokenBasicInfo.isPoolToken)) {
      const cpltChain: string | undefined = sdk?.token?.getBlockchain(denom)
      if (cpltChain) {
        defaultBlockA = cpltChain.slice(0, 1).toUpperCase() + cpltChain.slice(1)
      }
    }

    let defaultBlockB
    if (denomB) {
      const tokenPropertyB = tokenProperties[denomB]
      defaultBlockB = tokenPropertyB?.tokenBasicInfo.blockchain
    }
    return {
      blockchainA: overrideBlockA ?? defaultBlockA,
      blockchainB: overrideBlockB ?? defaultBlockB,
    }
  }, [denom, denomB, tokenProperties, overrideBlockA, overrideBlockB, sdk?.token])

  const chain = blockchainA ?? 'unknown'
  const chainB = denomB && blockchainB

  const chainStyles: SimpleMap = {
    Ethereum: classes.eth,
    'Binance Smart Chain': classes.bsc,
    Bitcoin: classes.btc,
    Zilliqa: classes.zilliqa,
    Carbon: classes.carbon,
    Native: classes.carbon,
    Tradehub: classes.tradehub,
    Osmosis: classes.osmosis,
    'Terra Classic': classes.terra,
    'Cosmos Hub': classes.cosmoshub,
    Juno: classes.juno,
    Evmos: classes.evmos,
    Axelar: classes.axelar,
    Stride: classes.stride,
    Kujira: classes.kujira,
    Terra: classes.terra2,
    Comdex: classes.comdex,
    Stafihub: classes.stafihub,
    'Persistence Core': classes.persistence,
    Stargaze: classes.stargaze,
    Arbitrum: classes.arbitrum,
    Polygon: classes.polygon,
    OKC: classes.okc,
    Canto: classes.canto,
    Sommelier: classes.sommelier,
    Decentr: classes.decentr,
    'IRIS Hub': classes.irishub,
    Agoric: classes.agoric,
    Noble: classes.noble,
    DYDX: classes.dydx,
    Archway: classes.archway,
    'Secret Network': classes.secret,
    Mantle: classes.mantle,
    OP: classes.op,
    Base: classes.base,
    unknown: classes.unknown,
  }

  const getChainStyles = (chain: string) => {
    if (isNeoBlockchain(chain)) return classes.neo
    return chainStyles[chain] ?? classes.unknown
  }

  const multipleChainStyles = (denomInput: string | undefined, chainInput: string) => (isMultiChainDenom(denomInput) && !showOriginal ? classes.multiChain : getChainStyles(chainInput))
  const multipleChainText = (denomInput: string | undefined, chainInput: string) => (isMultiChainDenom(denomInput) && !showOriginal ? 'Omnichain' : chainLabel(chainInput, true))

  const chainTag = multipleChainText(denom, chain)
  const chainTagB = chainB ? multipleChainText(denomB, chainB) : ''

  const isDifferentChainB = chainB && chainTag !== chainTagB


  return (
    <div className={clsx(className, classes.root, noMargin && classes.noMargin, commonClasses.alignItemsCenter)}>
      <div className={clsx(customTagClass, classes.tag, { right: isDifferentChainB }, multipleChainStyles(denom, chain))}>
        {chainTag}
      </div>
      {isDifferentChainB && (
        <div className={clsx(customTagClass, classes.tag, classes.merge, commonClasses.alignItemsCenter, commonClasses.justifyContentCenter, 'left', multipleChainStyles(denomB, chainB!))}>
          {chainTagB}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  noMargin: {
    margin: '0px !important',
  },
  root: {
    [theme.breakpoints.only('xs')]: {
      marginRight: '0.2rem',
    },
    textAlign: 'center',
    userSelect: 'none',
    overflow: 'hidden',
  },
  tag: {
    ...theme.typography.body4,
    padding: theme.spacing(0, 0.5),
    paddingBottom: '1px',
    height: '14px',
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    '&.left': {
      borderRadius: '0px 4px 4px 0',
      paddingLeft: '6px',
      clipPath: 'polygon(5px 0, 100% 0, 100% 100%, 0% 100%)',
    },
    '&.right': {
      paddingRight: '8px',
      borderRadius: '4px 0 0 4px',
      clipPath: 'polygon(0 0, 100% 0, calc(100% - 5px) 100%, 0% 100%)',
    },
  },
  chainLink: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    padding: '0rem 0.0625rem',
  },
  merge: {
    marginLeft: '-3px',
  },
  eth: {
    background: 'linear-gradient(180deg, #7F7CFF 0%, #4D4ABF 100%)',
  },
  bsc: {
    background: 'linear-gradient(180deg, #ECAB00 0%, #976D00 100%)',
  },
  btc: {
    background: '#EB9B32',
  },
  neo: {
    background: 'linear-gradient(180deg, #0BC889 0%, #079163 100%)',
  },
  tradehub: {
    background: '#4276E6',
  },
  zilliqa: {
    background: 'linear-gradient(180deg, #1AA4AC 0%, #0A7D84 100%)',
  },
  carbon: {
    background: 'linear-gradient(180deg, #22C0BF 0%, #0E8988 100%)',
  },
  osmosis: {
    background: 'linear-gradient(180deg, #602BD0 0%, #4B1CAF 100%)',
  },
  terra: {
    background: 'linear-gradient(180deg, #397EEB 0%, #1F54A8 100%)',
  },
  cosmoshub: {
    background: 'linear-gradient(180deg, #5064FB 0%, #2F41C8 100%)',
  },
  juno: {
    background: 'linear-gradient(180deg, #DA6761 0%, #AE453F 100%)',
  },
  evmos: {
    background: 'linear-gradient(180deg, #DB482F 0%, #B9351E 100%)',
  },
  axelar: {
    background: 'linear-gradient(180deg, #252525 0%, #000000 100%)',
  },
  stride: {
    background: 'linear-gradient(180deg, #E50571 0%, #9A004A 100%)',
  },
  kujira: {
    background: 'linear-gradient(180deg, #E53935 0%, #B31C19 100%)',
  },
  terra2: {
    background: 'linear-gradient(180deg, #0E3CA5 0%, #072A79 100%)',
  },
  comdex: {
    background: 'linear-gradient(180deg, #EB1B48 0%, #79041D 100%)',
  },
  stafihub: {
    background: 'linear-gradient(180deg, #6758FF 0%, #3327AA 100%)',
  },
  persistence: {
    background: 'linear-gradient(180deg, #505258 0%, #25262A 100%)',
  },
  stargaze: {
    background: 'linear-gradient(180deg, #3BA592 0%, #1E7969 100%)',
  },
  arbitrum: {
    background: 'linear-gradient(180deg, #1C91DF 0%, #08609B 100%)',
  },
  polygon: {
    background: 'linear-gradient(180deg, #8247E5 0%, #491C95 100%)',
  },
  okc: {
    background: 'linear-gradient(180deg, #4A4A4A 0%, #131313 100%)',
  },
  canto: {
    background: 'linear-gradient(180deg, #08CB7C 0%, #048E57 100%)',
  },
  sommelier: {
    background: 'linear-gradient(180deg, #ED4D7A 0%, #422044 100%)',
  },
  decentr: {
    background: 'linear-gradient(90deg, #4F80FF -0.01%, #4E7FFC 27.99%, #4A7CF3 47.99%, #4477E4 63.99%, #3C6FCE 78.99%, #3166B3 92.99%, #2B60A3 99.99%)',
  },
  irishub: {
    background: 'linear-gradient(36deg, #0A86C2 0%, #694D9F 50%, #CA3153 100%)',
  },
  agoric: {
    background: 'linear-gradient(180deg, #C32942 0%, #7D1729 100%)',
  },
  noble: {
    background: 'linear-gradient(0deg, #7083FF 0%, #74A4FF 100%)',
  },
  dydx: {
    background: 'linear-gradient(180deg, #4D4C6A 0%, #282844 100%)',
  },
  archway: {
    background: 'linear-gradient(180deg, #FF4D00 0%, #C23B00 100%)',
  },
  secret: {
    background: 'linear-gradient(180deg, #393939 0%, #262626 100%)',
  },
  mantle: {
    background: 'linear-gradient(180deg, #707070 0%, #353535 100%)',
  },
  op: {
    background: 'linear-gradient(180deg, #EB1B48 0%, #79041D 100%)',
  },
  base: {
    background: 'linear-gradient(180deg, #00A8FF 0%, #0065FC 100%)',
  },
  unknown: {
    background: '#6a748acc',
  },
  multiChain: {
    backgroundImage: `url("${MultiChainGradient}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}))

export default ChainTag
